import { getAccount } from '@/utils/auth'
// 盘口信息缓存 按account存储盘口信息
export function getTradingPairInfo(type) {
    var account = getAccount()
    // 默认盘口信息
    var tradingPairDefaultInfo = {
        exchange: 'Binance',
        web: 'bian',
        yenUnit1: 'BTC',
        yenUnit2: 'USDT',
        web_id: '5',
        web_type: '0',
    }
    if (localStorage.getItem('tradingPairDefault')) {
        var tradingPairDefaultObj = JSON.parse(localStorage.getItem('tradingPairDefault'))
        if (tradingPairDefaultObj[account]) {
            return tradingPairDefaultObj[account][type];
        } else {
            return tradingPairDefaultInfo[type];
        }
    } else {
        return tradingPairDefaultInfo[type];
    }
}

export function setTradingPairInfo(tradingPairInfo) {
    var account = getAccount()
    if (localStorage.getItem('tradingPairDefault')) {
        var tradingPairDefaultObj = JSON.parse(localStorage.getItem('tradingPairDefault'))
        tradingPairDefaultObj[account] = { ...tradingPairDefaultObj[account], ...tradingPairInfo }
    } else {
        var tradingPairDefaultObj = {}
        tradingPairDefaultObj[account] = tradingPairInfo
    }
    try {
        localStorage.setItem('tradingPairDefault', JSON.stringify(tradingPairDefaultObj))
    } catch (err) {
        // 处理空间不足的情况
        localStorage.removeItem("tradingPairDefault");
        localStorage.setItem('tradingPairDefault', tradingPairDefaultObj)
    };
}

export function removeTradingPairInfo() {
    return localStorage.removeItem("tradingPairDefault");
}

// 交易所信息缓存 按account存储交易所信息-用户风控管理页专用
export function getWebInfo(type) {
    var account = getAccount()
    // 默认盘口信息
    var webDefaultInfo = {
        exchange: '',
        web: '',
        // web_id: '5',
        // web_type: '0',
    }
    if (localStorage.getItem('webDefault')) {
        var webDefaultObj = JSON.parse(localStorage.getItem('webDefault'))
        if (webDefaultObj[account]) {
            return webDefaultObj[account][type];
        } else {
            return webDefaultInfo[type];
        }
    } else {
        return webDefaultInfo[type];
    }
}

export function setWebInfo(webInfo) {
    var account = getAccount()
    if (localStorage.getItem('webDefault')) {
        var webDefaultObj = JSON.parse(localStorage.getItem('webDefault'))
        webDefaultObj[account] = { ...webDefaultObj[account], ...webInfo }
    } else {
        var webDefaultObj = {}
        webDefaultObj[account] = webInfo
    }
    try {
        localStorage.setItem('webDefault', JSON.stringify(webDefaultObj))
    } catch (err) {
        // 处理空间不足的情况
        localStorage.removeItem("webDefault");
        localStorage.setItem('webDefault', webDefaultObj)
    };
}

export function removeWebInfo() {
    return localStorage.removeItem("webDefault");
}