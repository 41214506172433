const { create } = require("js-md5");

module.exports = {
	common: {
		addAccount:'添加账号',
		currentAccount:'当前账号',
		customerService:'在线客服',
		gptMsg:'请在此输入您的消息...',
		sendGpt:'发送',
		clearGptHistory:'清空记录',
		socketClose:'检测到连接断开，正在重连...',
		replyWait:'客服正在努力回复中，请稍等~',
		title:'闪电',
		endtime:'有效时间',
		contact: '联系我们',
		exchange:'交易所',
		searchExchange:'搜索交易所',
		pair:'交易对',
		opensea:'藏品token',//collection
		currency:'币种',
		available:'可用',
		frozen:'冻结',
		change:'变动',
		total:'合计',
		currentOpen:'当前盘口',
		collection:'收藏',
		commonly:'常用盘口',
		explain:'说明',// XX机器人说明 / XX工具说明
		addTile:'添加',// 添加XX机器人
		editTitle:'编辑',// 编辑XX机器人
		description:'功能说明',
		num:'数量',
		pirce:'价格',
		purchase:'买入',
		sellout:'卖出',
		closed:'已成交',
		direction:'方向',
		time:'时间',
		operation:'操作',
		recharge:'充值',
		withdrawal:'提现',
		exchangeSelect:'交易所选择',
		whole:'全部',
		agreement:'用户协议',
		mainAddress:'资金钱包地址',
		subAddress:'未授权地址',
		gasCurrency:'gas币种',
		token:'授权代币',//授权代币列表-多个token
		filter:'筛选',
		content:'内容',
		selectTime:'选择时间',
		valuation:'估值',
		proportion:'资金占比',
		gas:'手续费',
		targetAddress:'目标地址',
		balance:'余额',
		to:'到',
		second:'秒',
		warning:'警告',
		success:'成功',
		notice:'注意',
		slippage:'滑点',
		selectAll:'全选',
		recommend:'推荐',
		securityCode:'安全码',
		securityCodePH:'请输入安全码',
		walletAddress:'钱包地址',
		all:'全部',
		cex:'现货',
		dex:'DEX',
		futures:'合约',
		common:'常用',
		contracts:'张',
		transactionTime:'成交时间',
		hedgingStatus:'对冲状态',
		hedged:'已对冲',
		notHedged:'未对冲',
		hedgingDirection:'对冲方向',
		hedgingExecutionPrice:'对冲成交价',

		// 按钮
		renew:'续费',
		open:'开通',
		handicap:'进入盘口',
		into:'进入',
		selectOpen:'选择盘口',
		back:'返回',
		next:'下一步',
		pre:'上一步',
		continueAdd:'继续添加',
		add:'添加',
		add2:'新增',
		edit:'编辑',
		modify:'修改',
		bind:'绑定',
		remove:'解绑',
		removeBind:'解除绑定',
		toOpen:'去开通',//add
		backlist:'返回列表',
		search:'搜索',
		confirm:'确定',
		beforeDay:'前一天',
		nextDay:'后一天',
		submit:'提交',
		cancel:'取消',
		confirm2:'确认',
		save:'保存',
		saveRun:'保存并启动',
		restart:'重启',
		copy:'复制',
		cancelOrder:'撤单',
		start:'开始',
		stop:'停止',
		run:'启动',
		del:'删除',
		permanentlyDel:'彻底删除',
		toBind:'去绑定',
		toAuthorize:'去授权',
		authorize:'授权',
		toAllocation:'去分拨',
		toErrLog:'查看错误日志',
		allotAuthorize:'分拨授权',
		directAuthorize:'直接授权',
		ignore:'忽略',
		viewBtn:'查看',
		refused:'残忍拒绝',
		update1:'马上更新',
		update2:'立即更新',
		downLoad:'导出',
		override:'覆盖',
		switchAccount:'切换账号',
		settings:'前往设置',
		viewLog:'查看日志',
		toEnable:'去开启',
		enableNow:'立即开启',
		more:'查看更多',
		deselect:'取消选中',
		long:'开多',
		short:'开空',
		priceDecimals:'价格精度',
		numDecimals:'数量精度',
		makerFee:'挂单手续费',
		takerFee:'吃单手续费',

		// 单选
		yes:'是',
		no:'否',

		// 输入框占位符
		select:'请选择',
		input:'请输入',
		value:'数值',
		valueOptional:'数值（选填）',
		minValue:'数值下限',
		maxValue:'数值上限',
		inputVerification:'请输入验证码',
		selectdate:'选择日期',
		startdete:'开始日期',
		enddete:'结束日期',
		keyword:'请输入搜索关键字',
		optional:'选填',
		timePH:'时间点，如：2024-01-01 01:01:01',

		telegram:'电报',
		pwd:'密码',
		email:'邮箱',
		emailAddress:'邮箱地址',
		newPwd:'新密码',
		confirmNewPwd:'确认新密码',
		oldEmail:'旧邮箱',
		newEmail:'新邮箱',
		verification:'邮箱验证码',
		verification_s:'验证码',
		countDown:'倒计时',
		sendVerification:'发送验证码',
		resend:'重新发送',

		max:'最大',
		maxBtn:'最大',

		// sub
		userName:'用户名',
		login:'登录',
		register:'注册',
		logOut:'注销',

		documentation:'文档',
		freeRegistration:'免费注册',

		loding:'加载中',
		noMore:'没有更多了',
		noData:'暂无数据',

		subManage:'钱包管理',
		toSeeBtn:'去查看',
		enabled:'已启用',
		notEnabled:'已停用',

		// 交易记录
		transactionRecord:'交易记录',
		address:'地址',
		tradingDirection:'交易方向',
		transactionAverage:'交易均价',
		transactionNum:'交易数量',
		transactionAmount:'成交金额',
		orderStatus:'订单状态',
		orderStatus1:'交易失败',
		orderStatus2:'交易成功',
		orderStatus3:'交易中',
		viewTX:'查看交易',
		
		query:'查询',
		toLogin:'去登录',
		loginAgain:'重新登录',
		refresh:'刷新',
		// toClose:'去关闭'
	},
	// 新手引导
	guide:{
		skipStep:'跳过此步骤',
		skipTutorial:'跳过新手指引',
		selectWeb:'选择交易所',
		selectWebSub:'选择一个交易所并填写交易对',
		bindAccount:'绑定账号',
		bindAPI:'绑定API',
		bindAPISub:'请使用真实资金',
		iniContract:'初始化合约',
		contractSub:'请填写代币合约地址',
		bindWallet:'绑定钱包',
		bindWalletSub:'请在下面填写私钥和钱包地址',
		distribution:'分拨资金',
		distributionSub:'回复以下问题完成分拨及授权',
		subWalletNum:'需要创建几个交易钱包',
		allotAuthorize:'是否授权系统代为管理资金的分拨和token授权',
		accountBalance:'资金情况',

		selectRobot:'选择机器人',
		welcome:'欢迎',
		guideDetail:'完成以下新手指引快速了解Lightning MM(整个流程大约五分钟)',

		// placeholder
		baseCurrencyPH:'请输入基础货币',
		quoteCurrencyPH:'请输入计价货币（如USDT，ETH，BNB，USDC）',
		contractPH:'请输入代币合约地址',
		walletAddressPH:'请输入钱包地址',
		privateKeyPH:'请输入私钥',
		fillIn:'请输入{name}',
		req:'问题',
		subWalletNumPH:'请输入创建数量',
		allotAuthorizePH:'请选择是否授权系统代为分拨授权',
		allocateNumPH:'请输入分拨数量',

		req1:'为什么绑定？',
		ans1:'加密货币交易所是您买卖加密货币的平台，您的资金留存在交易所。若要使用Light开启自动交易，您需要绑定交易所并开启交易权限。',
		
		// step2
		// cex
		bindapi_title:'在{exchange}上创建API密钥',
		bindapi_1:'登录{exchange}',
		bindapi_2:'在左上角工具栏找到<API>',
		bindapi_3:'按交易所指引完成创建API',
		bindapi_4:'选择密码短语并添加IP地址',
		ipWhitelist:'IP地址白名单',
		bindapi_5:'启用“交易”并点击确认',
		bindapi_6:'将您的密钥和密码复制粘贴到此处',
		// dex
		req2_1:'为什么需要在闪电后台添加币的合约地址？',
		ans2_1:'dex市场中允许symbol相同的代币，合约地址是辨别代币最准确的方式',
		req2_2:'如何获取合约地址？',
		ans2_2:'请咨询撰写dex合约的技术人员',
		req2_3:'为什么需要绑定钱包地址和私钥？',
		ans2_3:'用于将外部做市资金导入系统的交易钱包中，绑定的钱包地址（也叫资金钱包/主钱包）只起到一个资金转移的过渡作用',
		req2_4:'如何获取钱包地址和私钥？',
		ans2_4:'请在链上钱包中获取',
		req2_5:'钱包地址和私钥在闪电平台是否安全？',
		ans2_5:'闪电平台在用户提交私钥或高敏感信息时会进行多重加密，并实施密文存储，可以确保私钥信息的安全',
		req2_6:'为什么需要设置交易钱包？',
		ans2_6:'dex交易所每笔交易的地址对所有人可见，为避免被散户看出做市方动作，一般选择用多个交易钱包伪装用户交易',
		req2_7:'交易钱包是否安全？',
		ans2_7:'交易钱包在平台生成后会进行多重加密，并实施密文存储，交易钱包的私钥是任何人无法获取的',
		req2_8:'需要创建多少个交易钱包？',
		ans2_8:'创建20个钱包（适用于低成本方案）<br/>创建50个钱包（适用于标准方案）<br/>请根据您的具体需求，创建适当数量',
		
		// 检测loading
		checkErr:'检测失败',
		checkIng:'正在检测...',
		checkBind:'检测用户绑定的{name}',
		checkSuc:'检测成功',
		checkBindErr:'绑定失败，请检查{name}',

		// 机器人引导
		configuration:'机器人配置引导',
		configurationSub:'回复以下问题完成新手指引',
		// cex
		openCexLabel:'配置开盘机器人',
		openCexTip:'用于在开盘的一瞬间打好开盘价，最高价，回落到预定的横盘价格',
		req3_1:'您是否已开盘',
		budanNewLabel:'配置盘口管理机器人',
		budanNewTip:'用于控制盘口买一卖一宽度, 买盘卖盘深度, 并且保持盘口挂单撤单活跃度,提高盘口活跃度',
		req3_2:'设置盘口数量参数',
		numberOfOrderLevels:'挂单档数',
		numberOfOrderLevelsPH:'请设定您想要挂出的挂单数量',
		spreadPerLevel:'每档价差',
		spreadPerLevelPH:'请设定每笔挂单之间的价差',
		shuaLabel:'配置成交量对敲机器人',
		shuaTip:'用于刷盘口成交量,提高盘口活跃度',
		req3_3:'设置每天成交量',//同时reqDex3_3
		dailyRequiredWashTradingVolume:'每天所需成交量',
		minValueOfTheQuantityRangePerWashTrade:'每次成交数量范围的最小值',
		maxValueOfTheQuantityRangePerWashTrade:'每次成交数量范围的最大值',
		// dex
		frontrunDexLabel:'配置Dex定时买入机器人',
		frontrunDexTip:'机器人将按设定的时间买入一笔设定金额的订单。可能会由于价格波动过大导致买入价格不理想或买入失败。',
		reqDex3_1:'你是否需要开盘抢买',
		shuaDexLabel:'配置Dex画线机器人',
		shuaDexTip:'用于在给定的价格范围中, 做随机波动, 并制造一些成交量,提高盘口活跃度并绘制dex品种交易k线。',
		reqDex3_2:'设置机器人工作范围',
		reqDex3_2Tip:'* 数字由低到高填写, 用来控制画线范围, 超出范围时, 机器人会暂停工作并继续监听价格',
		req3_3Tip:'* 数字由低到高填写,每次交易的交易量随机范围,范围可以设置大一些保证交易记录看起来更自然',
		maxPurchasePrice:'最高买入价',
		gasMultiplier:'Gas倍数',
		openingPurchaseAmount:'开盘买入金额',
		
		runRobot:'启动机器人',
		settingSuc:'设置成功',
		runRobotDexLabel:'请注意：',
		runRobotDexTip:'机器人参数设置基于交易对的价格，而非USDT价格。例如，在BNB交易对中，所有操作均依据BNB的价格波动，而非USDT的价格变化。',
	},
	head:{
		lv:'用户等级',
		center:'用户中心',
		invite:'邀请好友',
		notice:'最新公告',
		out:'退出登录',
		outTip:'确定退出登录？',
	},
	aside:{
		list:[
			'账号列表',
			'手动操盘',
			'操盘机器人',
			'机器人日志',
			'数据报表',
			'资产报表',
			'报警系统',
			'用户设置',
			'用户中心',
			'邀请好友',
			'支持',
			'新手教程',
			'联系我们',
		],
		renew:'续费开通',
	},
	// 官网相关
	website:{
		index:{
			lightMM:'闪电市值管理机器人',
			mm:'市值管理机器人',
			// sub:'稳定运行6年, 最安全的交易机器人, 现已支持各大Dex',
			sub:'稳定运行6年, 最安全的市值管理机器人, 现已支持各大Dex',
			project:'项目方',
			multiChain:'支持多链Dex',
			// f2
			fullyAutomatic:'全自动价格波动',
			followSystem:'行情跟随系统',
			sellSuck:'高抛低吸套利',
			assetWarning:'资产预警系统',
			// f3
			packages:'套餐',
			month:'月',
			// f4
			features:'产品特色',
			featuresDetail:'安全、强大、便捷',
			safe:'安全',
			safeSub:'资产存放交易所 账号资料多重加密',
			power:'强大',
			// powerSub:'机器人内核千锤百炼',
			powerSub:'市值管理机器人内核千锤百炼',
			convenient:'便捷',
			convenientSub:'多账号聚合管理 多端自由操作',
			efficient:'高效',
			efficientSub:'高效的运行',
			// f5
			customers:'受众客户',
			who:'我们为谁服务？',
			market:'做市商',
			incubator:'孵化机构',
			// foot
			links:'友情链接',
			// storm:'风暴跟单',
			// lightning:'闪电量化',
			follow:'Follow量化',
			LightningDexTools:'Lightning DexTools',
			service:'服务帮助',
			cooperation:'商务合作',
			problem:'常见问题',
			terms:'条款说明',
			privacy:'隐私帮助',
		},
		agreement:{
			protocol:'用户协议',
			content:`
			<p>闪电系统由新加坡巨幕资本研发和负责运行，公司运营网站 http://www.llkkz.net（以下称“本网站”或“网站”）是一个提供区块链产业服务（以下称“该服务”或“服务”）的平台。为了保障您的权益，请于注册或使用本网站前，仔细阅读并完全理解本协议条款的全部内容。
			</p><br/>
			<h3>一、总则</h3>
			<p>
				1.1 《用户协议》（以下称“本协议”），由正文、《隐私条款》以及本网站已经发布的或将来可能发布的各类规则、声明、说明等构成。<br/>
				1.2 您在使用本网站提供的各项服务之前，应仔细阅读本协议，如有不理解之处或其他必要，请咨询专业律师。如您不同意本协议及/或随时对其的修改，请您立即停止使用本网站提供的服务或不再登陆本网站。您一旦登陆本网站、使用本网站的任何服务或任何其他类似行为即表示您已了解并完全同意本协议各项内容，包括本网站对本协议随时所做的任何修改。<br/>
				1.3 您通过按照本网站的要求填写相关信息，并经过其他相关程序后成功注册可以成为本网站的用户（以下称“用户”），在进行注册过程中点击“同意”按钮即表示您以电子签署的形式与公司达成协议；或您在使用本网站过程中点击任何标有“同意”或类似意思的按钮的行为或以其他本网站允许的方式实际使用本网站提供的服务时，均表示您完全了解、同意且接受本协议项下的全部条款的约束，就本协议而言，无您手写的书面签字对您的法律约束力无任何影响。<br/>
				1.4 您成为本网站的用户后，您将获得一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；您应当对以您帐号进行的所有活动和事件负法律责任。<br/>
				1.5 只有成为本网站的用户才可使用本网站提供的区块链产业服务；非注册用户只可以浏览网站内容。<br/>
				1.6 通过注册和使用任何由本网站提供的服务和功能，您将被视为已阅读，理解并：<br/>
				1.6.1 接受本协议所有条款及条件的约束；<br/>
				1.6.2 您确认您已年满18周岁或根据不同的可适用的法律规定的具有可订立合同的法定年龄，您在本网站的注册、发布信息等接受本网站服务的行为应当符合对您有管辖权的主权国家或地区相关法律法规，并有充分的能力接受这些条款；<br/>
				1.6.3 您确认注册时提供的信息是真实和准确的；<br/>
				1.6.4 您同意遵守任何有关法律的规定；<br/>
				1.6.5 您同意在任何时候不得从事或参与损害本网站或闪电机器人的行为或活动，无论是否与本网站提供的服务有关。
			</p>
			<br/>
			<h3>二、协议修订</h3>
			<p>2.1 闪电保留不时修订本协议、并以网站公示的方式进行公告、不再单独通知您的权利，变更后的协议一经在网站公布，立即自动生效。您应不时浏览及关注本协议的更新变更时间及更新内容，如您不同意相关变更，应当立即停止使用本网站服务；您继续使用本网站服务，即表示您接受并同意经修订的协议的约束。
			</p>`,
		},
		problem:{
			question1:'系统如何保证账户资金安全？',
			answer1:'双重保护：1.闪电市值管理机器人具备多重防护模块可以应对不同攻击和套利行为。2. 报警系统可以根据您的设置对盘面进行监控，出现异常立即停止工作。',
			question2:'一个闪电市值管理机器人账号能否对接多个交易所？',
			answer2:'闪电市值管理机器人目前已支持超过60家交易所， 您可以在单个闪电账号里绑定多个交易所的交易账号，完成多账号的聚合管理。',
			question3:'如何查看资产盈亏？',
			answer3:'闪电市值管理机器人每日0点会记录当日账户余额情况，您可以通过对比每日余额的变动查看历史每天的盈亏情况。',
			question4:'密码忘记如何找回？',
			answer4:'请联系客服，客服会与您核对账户所有权并为您重置登录密码，登陆进去后可以在个人中心修改密码。',
			question5:'其他',
			answer5:'有任何使用问题或者对产品的建议，我们非常欢迎您联系我们，我们会尽最大努力满足您的的使用需求！感谢您对闪电市值管理机器人的支持。',
		},
		contact:{
			text1:'闪电(Light)云系统是由新加坡LIGHT Tech.于2016年总结业内成熟的操盘手法, 耗费1年时间倾力设计研发完成. 作为业内最专业的做市/交易机器人供应商，系统已持续运行6年，服务了数百家项目方做市团队。',
			text2:'闪电云系统目前支持超过200+家中心化交易所, 最新的{version}系统已率先支持多家Dex, 成为业内领先的市值机器人服务商, 系统策略机器人品类丰富, 是您的做市/交易的最佳选择。',
			business:'商务合作',
			service:'系统客服',
		}
	},
	// 登录相关模块
	login:{
		loginTitle:'登录闪电',
		forget:'忘记密码？',
		toRegister:'没有账号？去注册',
		agree:'同意闪电',
		version:'版本',
		// 忘记密码
		reset:'重置密码',
		confirm:'确认密码',
		confirmReset:'确认重置',
		// 注册
		register:'注册账号',
		toLogin:'已有账号？去登录',
		// 安全登录
		safeLogin:'安全登录验证',
	},
	// 提示
	tip:{
		usernameEmpty:'请输入用户名',
		accountPlaceHolder:'6位以上,且必须含有字母和数字',
		accountVer:'用户名必须是6位数以上，且必须含有字母和数字',
		emailEmpty:'请输入邮箱',
		emailVer:'请输入正确的邮箱',
		codeEmpty:'请输入验证码',
		pwdEmpty1:'请输入密码',
		pwdEmpty:'请输入新密码',
		pwdVer:'密码必须是8位以上, 且必须包含符号、大小写字母、数字',
		pwd2:'两次密码不一致',
		commonVer:'8位以上, 且必须包含符号、大小写字母、数字',
		again:'请再次确认新密码',
		invite:'邀请码',
		inviteVer:'请输入邀请码',
		check:'需要先勾选用户协议',
		tip:'提示',
		tips:'温馨提示',
		expired:'您的使用权限已到期，如需使用，请您续费充值！',
		waiting:'功能开发中，敬请期待！',
		waiting2:'功能尚未开通，请联系客服！',
		registerSuc:'注册成功！',
		copysuccess:'复制成功！',
		copyerr:'该浏览器不支持复制！',
		timeFormatErr:'无效的日期格式，请使用 YYYY-MM-DD HH:mm:ss 格式',
		timeFormatErr2:'无效的日期，请输入有效的日期和时间',
		currencyEmpty:'请选择币种',
		exportEmpty:'暂无数据可导出',
		// 收藏
		collectionSuc:'收藏成功',
		cancelCollectionSuc:'取消收藏成功',
		// 账号列表
		pleaseEnter:'请输入{name}',
		addSuc:'添加成功！',
		addErr:'添加失败！',
		delTip:'确认要删除吗？',
		delSuc:'删除成功！',
		delErr:'删除失败！',
		// 手动操盘
		selectExchange:'请选择交易所',
		enterPair:'请输入交易对',
		enterCollect:'请输入藏品token',
		numEmpty:'请输入数量！',
		priceEmpty:'请输入价格！',
		buySuc:'买入成功！',
		sellSuc:'卖出成功！',
		handicap:'请进入盘口！',
		cancelOrder:'确定撤销订单？',
		cancelSuc:'撤销成功！',
		manuallyBuy:'确定手动下买单？',
		manuallySell:'确定手动下卖单？',
		manuallyLong:'确定开多？',
		manuallyShort:'确定开空？',
		enterClosePrice:'请输入平仓价格',
		enterCloseNum:'请输入平仓数量',
		confirmCloseposition:'确定平仓？',
		confirmCloseAllPosition:'确定市价全平？',
		needSell:'需卖出{num1}，获得{num2}',
		needBuy:'需消耗{num1}，买入{num2}',
		calculationRes2:'目标价{num1}，滑点{num2}',
		total500:'总单数不能大于500',
		needConfigAlarm:'是否需要配置报警系统?',
		notShowWeek:'一周内不再提示',
		downLoadTip:'是否确认导出数据？',
		// 交易记录
		recordListTip:'仅显示3个月内记录',
		// 交易钱包管理
		privateKey:'请输入交易钱包私钥！',
		toSee:'具体结果请查看日志！',
		toMainRefresh:'确认要刷新资金钱包吗？',
		// createSuc:'<div>交易钱包创建成功！<br/><span style="color:#E65B1B">【注：若您使用交易钱包，请您及时给交易钱包分拨资金！】</span></div>',
		createSuc:'<div>交易钱包创建请求已提交！具体结果请查看日志。<br/><span style="color:#E65B1B">【注：若您使用交易钱包，请您及时给交易钱包分拨资金！】</span></div>',
		num0:'数量不可为0',
		totalQuantity0:'总数量不可为0',
		closeCollectionTip:'关闭不会终止分拨操作，具体结果请您查看日志。',
		closeAllocationTip:'关闭不会终止归集操作，具体结果请您查看日志。',
		frequentTip:'您的操作过于频繁，请稍后再试！',
		exceedBalance:'您输入的数量不可以超过您的余额',
		currentTokenIsBeingAllocated:'当前代币正在分拨中，请稍后再试！',
		distributionScheduleErr:'资金分拨失败，请联系客服！',
		poolScheduleErr:'资金归集失败，请联系客服！',
		
		// 操盘机器人
		saveSuc:'保存成功！',
		saveErr:'保存失败！',
		startRobot:'确认要启动机器人吗？',
		startSuc:'启动成功！',
		startErr:'启动失败！',
		stopRobot:'停止机器人可能会导致一些问题，确认要停止机器人吗？',
		saveRobot:'确认要保存机器人参数吗？',
		stopSuc:'停止成功！',
		stopErr:'停止失败！',
		dataErr:'参数不完整！',
		operationSuc:'操作成功！',
		waitConfirmation:'交易已提交, 等待区块确认',
		toRefresh:'确认要余额刷新吗？',
		refreshSuc:'刷新成功！',
		processing:'提交成功！系统正在处理',
		calculationSuc:'测算结果：',
		noRobot:'暂无运行中机器人！',
		closingErr:'正在关闭中，请稍后...',
		closing:'{time}s 后将自动关闭',
		restart:'机器人重新启动中，请稍后... {time}',
		mainSaveRunTip:'您当前资金钱包地址是参与交易状态，机器人设置后，有概率将参与到下单交易。若您不希望资金钱包地址参与交易，请您先去关闭后再启动。',
		mainRunTip:'资金钱包处于开启状态，会参与下单交易。若您不希望这样，请先前往[钱包管理系统]关闭资金钱包。',
		priceErrQuShi:'单笔预算必须小于等于总预算',
		priceDifferenceErr:'每档差价必须小于等于盘口差价',
		paramSame:'检测到参数与已启动的机器人一致，请修改后再保存',
		notBindTG:'是否需要将系统消息发送到您的电报账号，如果现在不需要，您以后也可以通过【报警系统】页面的【绑定电报】入口进行设置',
		// 机器人
		calculate:'确认计算？',
		balanceRefresh:'确定更新账户钱包余额？',
		delieverToken:'确认从资金钱包分拨资金到交易钱包？',
		collectToken:'确认从交易钱包归集资金到资金钱包？',
		walletsApprove:'确定钱包授权该代币交易？',
		rangeErr:'选择的时间范围不能超过7天',
		// 数据报表
		exportSend:'交易明细稍后将发送至您的邮箱',
		sendSuc:'发送成功，请注意查收！',
		noEmail:'您当前未绑定邮箱，请绑定邮箱后使用',
		// 资产报表
		export:'导出 {exchange} 交易所报表',
		selectTimeTip:'请选择时间范围',
		// 报警系统
		noTeleram:'暂未绑定电报，去绑定电报',
		startAlarm:'确认要启动报警器吗？',
		stopAlarm:'停止报警器可能会导致一些问题，确认要停止报警器吗？',
		alarmAdvantage:'开启报警系统，确保您的账户安全',
		alarmIsEnable:'报警系统已启用',
		alarmMainTip:'开启报警系统，实时监控异常活动，确保您的账户安全',
		// 续费开通
		discountTip1:'日常活动：季度付可享8折优惠，半年付可享7折优惠，年付可享6折优惠。',
		// discountTip2:'限期活动：2022年11月1日 ~ 2022年12月30日，使用钱包系统续费，可享95折优惠。',
		renewalSuc:'续费成功！',
		renewalInsufficient:'续费失败，您的钱包余额不足，请您充值！',
		setPackageSuc:'套餐切换成功！',
		setPackageInsufficient:'修改套餐失败，您的钱包余额不足，请您充值！',
		txSeachErr:'续费hash暂未同步，请您稍后再试！',
		txSeachErr2:'hash暂未同步，请您稍后再试！',
		notSupported:'闪电钱包不支持查看！',
		withdrawRestrictions:'提现金额最低50USDT！',
		addressEmpty:'提现地址不可为空！',
		VerificationEmpty:'验证码不可为空！',
		
		withdrawalSuc:'您的提现申请已提交，请在提现记录中查看申请的状态',

		editSuc:'修改成功！',
		editErr:'修改失败！',

		// 用户中心
		emailRepeat:'新邮箱与旧邮箱重复！',
		notBindEmail:'请先绑定邮箱！',
		ipClose:'关闭IP验证后账号可能会存在风险，您确定关闭？',
		ipCloseTip:'【注：关闭后一周内免IP验证，一周后会自动再次开启】',
		remove:'电报解绑成功！',

		// req
		ipErr:'当前IP失效，为防止使用异常，请您重新登录！',
		unknownErr:'系统正忙，请您稍后再试',//'未知错误',
		// 1003 1004
		update:'更新内容：',
		// 2015
		// ipVerTip:'【注：可前往用户中心关闭IP验证（不推荐）】',

		confirmAuthorize:'确认DEX钱包授权代币：',
		authorizeSuc1:'授权成功！',
		authorizeSuc:'授权成功，请重新操作！',
		mainAuthorizeErr:'【注：请检查账号资金钱包gas是否充足。】',
		authorizeErr:'【注：因为gas不足钱包授权失败，请前往钱包管理界面确认失败的钱包gas是否充足】',
		authorizeErr1:'【注：因为gas不足钱包授权失败，请确认失败的钱包gas是否充足】',
		// 2109、2173
		authorizeErr2:'【注一：因为gas不足钱包授权失败，请您点击分拨授权或前往钱包管理界面检查钱包gas余额。】<br/>【注二：若您是新导入钱包或新开启的钱包确定钱包gas充足情况下请您点击直接授权。】',
		// 2172
		authorizeErr3:'【注：因为gas不足钱包授权失败，请您点击去分拨或前往钱包管理界面检查钱包gas余额。】',
		
		// 2176 获取余额、充值地址
		renewErr:'支付续费钱包未生成，请您联系客服，给您带来的不便请谅解!',
		renewTip:'请您联系商务客服续费！',
		// renewTip2:'暂不支持手动续费，给您带来的不便请谅解，请您联系商务客服。',
		// renewTip3:'您的账号暂不能使用续费功能，请您联系商务客服，给您带来的不便请谅解。',
		// renewTip4:'您的账号暂不能修改套餐，请您联系商务客服，给您带来的不便请谅解。',
		// renewTip5:'暂不支持修改套餐，给您带来的不便请谅解，请您联系商务客服。',
		renewTip6:'检测到您有续费中记录，钱包暂时已锁，请您稍后再试，可去充值页面查看钱包记录核对。给您带来的不便请谅解，若有问题请您联系商务客服!',

		// 服务器错误
		serverErr:'服务器繁忙, 请稍后再试！',
		// 服务器错误2 请求超时
		timeout:'请求超时, 请检查您的网络环境是否正常后稍后再试！',
		// gpt响应错误
		gptErr:'在生成响应时出了点问题，请稍后再试！',
	},

	// 账号列表
	account:{
		title:'账号列表（说明：目前每个交易所限绑一个账号）',
		create:'创建账号',
		bind:'绑定新账号',
		toCreate:'暂无账号，去创建',
		newAccount:'新建账号',
		accountName:'备注',
		whiteList:'IP白名单',
		reminder:'重要提醒: ',
		dexReminder:'该钱包建议作为资产的中转钱包使用, 请将适量资金存入后并尽快分拨到系统内创建的交易钱包里',
		tip:'获取到交易所的{key}和{secret}后，注意填写时不要有空格',
		tip2_1:'如果不知道如何获取APIKEY等信息. ',
		tip2_2:'请咨询交易所客服',
		editAcc:'修改账号',
		delAcc:'删除账号',
		isCreateAddTip:'您已经绑定了这个交易所, 您可以找到他并且修改账号信息',
		keySecretSame:'您输入的{name1}和{name2}重复，请重新确认',
		
		walletAddress:'钱包地址',
		privateKey:'私钥',
		apiPWD:'API密码',
		payPWD:'支付密码',
		needNewWallet:'我需要新钱包',
		createWallet:'创建钱包',
		createWalletTip:'为确保资金安全，建议您选择由平台创建的资金钱包。',
		hasWallet:'已有钱包',
		importWallet:'导入钱包',
		importWalletTip1:'选择外部导入钱包时，请妥善保管您的私钥，',
		importWalletTip2:'若发生资金安全问题，平台将无法承担责任。',

		confirmCreate:'确认创建',
		mainWalletCreateSuc:'您的主钱包创建成功',
		mainWalletCreateSub:'平台仅保存私钥的一半，另一半私钥由您自行保管',
		mainWalletCreateTip:'请妥善保存您的私钥部分！如果遗失，资金将无法找回，且永远丢失！',
		mainWalletCreateCheckTip:'我已妥善保存私钥并理解遗失后果',
	},

	// 手动操盘
	stocks:{
		tradingTools:'操盘工具',
		orderBookMarketData:'盘口状态数据',
		robotVersion:'版',

		update:'自动更新',
		all:'所有盘口',
		current:'当前盘口',
		tradingView:'K线图表',
		openKline:'打开K线',
		closeKline:'关闭K线',
		toolSelect:'选择量化工具',
		tools:'量化工具',
		order:'手动下单',
		amount:'金额',
		realAmount:'拉盘砸盘实际消耗资金量',
		perAmount:'每档金额',
		orderLimit:'下单限额',
		exceedsLimit:'下单金额超过限额',
		intervalTime:'间隔时间',
		mainOrder:'资金钱包下单',
		dexTransactionRes:'确定花费 {volume}{token1} 兑换 {getMoney}{token2}',
		check:'买单挂单价格范围',
		sale:'卖单挂单价格范围',
		priceRange:'价格范围',
		quantity:'单量',
		quantityRange:'数量范围',
		total:'总单数',
		decimal:'价格小数点位数',
		target:'目标价',
		walletsNumber:'钱包数量',
		volume:'交易量',
		floatingRange:'浮动范围',
		strategy:'交易策略',
		strategyDefault:'系统默认',
		strategyCondition:'条件交易',
		transactionType:'交易类型',
		floatTip0:'浮动范围为0%，批量下单的钱包将会平均数量下单',
		chain:'链名',
		refresh:'余额刷新',
		wallets:'钱包数量',
		currency:'币种',
		totalQuantity:'总数量',
		totalCumulative:'总累计',
		token:'授权代币',
		tokenName:'代币名称',
		tokenNameTip:'请输入正确的代币名称',
		orderManagement:'订单管理',
		currentPosition:'当前持仓',
		common:'普通盘口',
		advanced:'高级盘口',
		coins:'砸盘所需币',
		coinsMoney:'拉盘所需U',
		investors:'散户数量',
		investorsTotal:'散户累计',
		myself:'我的数量',
		estimateVolume:'推算成交量',
		estimatePrice:'推算目标价',
		tradingVolume:'成交量',
		orderMode:'下单模式',
		normalMode:'普通模式',
		selfTradeMode:'自成交模式',
		
		robotMange:'机器人管理',

		buy:'买',
		sell:'卖',

		accountAssets:'账户资产',
		mainAssets:'资金钱包资产',
		subAssets:'交易钱包资产',
		mainAssetsNFT:'资金钱包NFT资产',
		subAssetsNFT:'交易钱包NFT资产',

		// nft
		contractAddress:'合约地址',
		date:'创建时间',
		items:'总发行量',
		owners:'持有人数',
		totalVolume:'总交易量',
		floor:'地板价',
		average:'均价',
		currency:'支持交易代币',
		more:'查看更多',
		less:'收起',
		
		// 选择盘口 常用盘口
		selectExchange:'选择交易所',
		enterPair:'输入交易对',
		
		// 合约
		limitOrder:'限价委托',
		marketOrder:'市价委托',
		positionQuantity:'持仓数量',
		liquidatableQuantity:'可平仓量',
		positionAmount:'持仓金额',
		averagePositionPrice:'持仓均价',
		unrealizedProfit:'浮盈',
		margin:'保证金',
		closeAll:'市价全平',
		closePosition:'平仓',
		goLong:'做多',
		goShort:'做空',
		fullPosition:'全仓',
		isolatedPosition:'逐仓',
		positionManagement:'头寸管理',
		totalPL:'账号总盈亏',
		positionList:'仓位列表',
		symbol:'品种',
		positionData:'仓位数据',
		longPosition:'多头持仓',
		shortPosition:'空头持仓',
		totalPosition:'总持仓',
		netPosition:'净头寸',
		longPL:'多头盈亏',
		shortPL:'空头盈亏',
		totalPL:'总盈亏',
		userRiskManagement:'用户风控管理',
		userList:'用户列表',
		searchUserName:'搜索用户名',
		addUser:'新增用户',
		userRiskDetail:'风控用户详情',
		showOnlyTrades:'仅显示{user}的交易',
	},
	// 交易钱包管理
	subManage:{
		totalAssets:'交易钱包总资产',
		useAssets:'交易钱包启用资产',
		notUseAssets:'交易钱包未启用资产',

		wallets:'钱包数量',
		totalAmount:'总数量',

		mainWallet:'资金钱包',
		address:'地址',
		chain:'链',
		assets:'资产',
		approveTokens:'授权列表',
		participate:'参与下单',
		transfer:'资金划转',
		transferAndAuthorize:'资金划转并授权',
		withdrawFunds:'资金提取',
		withdraw:'确认提取',
		subWalletList:'交易钱包列表',
		enable:'是否启用',
		currentState:'当前状态',
		start:'启用',
		stop:'停用',
		filterSearch:'筛选查询',
		groupMange:'组别管理',
		walletGroup:'钱包组别',
		groupFilter:'组别筛选',
		addGroup:'新增分组',
		groupNamePH:'请输入分组名',
		groupNameErr:'分组名长度不能超过40个字符',
		groupNameExists:'分组名已存在',
		delGroup:'删除分组',
		delGroupErr:'请选择您要删除的分组',
		delGroupTip:'您确定要删除当前分组吗？<br/>删除后，您的钱包地址将会移到默认分组中',
		replaceGroup:'替换分组',
		currentGroup:'当前分组',
		// 导出
		subWalletExport:'导出地址列表',
		exportTip:'【注: 这里导出的是所有已开启的钱包地址】',
		exportTotal:'共{num}个钱包',
		// 创建交易钱包
		createTip:'【注：为了您的资产以及账号安全, 系统生成的钱包私钥无法导出】',
		createTip2:'【注：交易钱包创建后, 若资金钱包不参与机器人和量化工具交易下单，请您及时关闭资金钱包参与下单按钮】',
		// 导入交易钱包
		key:'请复制粘贴交易钱包私钥（隔行粘贴）',
		currently:'当前已输入',
		entry:'行',
		importTip:'【注：为了您的资产以及账号安全, 存入私钥无法导出, 请您做好备份】',
		importTip2:'【注：交易钱包导入后, 若资金钱包不参与机器人和量化工具交易下单，请您及时关闭资金钱包参与下单按钮】',
		// 分拨
		distributionStrategy:'分拨策略',
		strategy:'策略',
		randomAllocate:'随机分拨',
		averageAllocate:'平均浮动分拨',
		conditionalAllocate:'条件分拨',
		floatRange:'浮动范围',
		walletBelow:'钱包少于',
		floatTip1:'浮动范围为0%，将会平均分配到每个交易钱包',
		floatTip2:'浮动范围>=80%，系统会将策略调整为随机分拨',
		fundsSource:'资金来源',
		currentProgress:'当前进度',
		allocating:'正在分拨…',
		collecting:'正在归集…',
		completed:'完成提交',
		skip:'跳过钱包',
		allocatFailed:'分拨失败',
		collectFailed:'归集失败',
		// 资金划转
		transferTip:'【注：为了您的资产安全, 资金划转目标地址只能是您账号的资金钱包或交易钱包】',
		single:'单钱包划转',
		multiple:'钱包分拨',
		targetAddressSame:'目标地址为同一钱包，同一钱包不能转账',
		safeVer:'安全验证',
		safeVerTip:'【注：您当前操作涉及金额较大，为确保资金安全，需要您进行邮箱验证】',

		// 来源
		enabled:'当前来源已启用',
		create:'系统创建',
		import:'外部导入',
		source:'钱包来源',
		onlyCreate:'仅显示系统创建',
		onlyImport:'仅显示外部导入',
		allOpen:'批量开启',
		allClose:'批量关闭',
		selectDelete:'删除选中',
		allOpenTip:'确定将所选钱包开启？',
		allCloseTip:'确定将所选钱包关闭？',
		selectDeleteTip:'确定将当前所选钱包删除？',
		selectedNum:'选中 {num}',
		searchWallet:'搜索钱包',

		// 交易限制
		transactionRestrictionModify:'交易限制修改',
		transactionRestriction:'交易限制',
		unlimited:'无限制',
		buyOnly:'只买',
		sellOnly:'只卖',
		allUnlimitedTip:'确定将当前所选钱包地址的交易限制修改为无限制？',
		allBuyOnlyTip:'确定将当前所选钱包地址的交易限制修改为只买？',
		allSellOnlyTip:'确定将当前所选钱包地址的交易限制修改为只卖？',
		limitTip:'交易限制：无限制（钱包参与下单买或者卖随机）、只买（钱包只能参与下单买入）、只卖（钱包只能参与下单卖出）',

		// NFT
		openBtnTip:'点击后在列表下方展示NFT资产',
		openBtn:'展示',
		closeBtn:'收起',
		copyAddress:'复制地址',
	},

	robotLevel:[
		'基础机器人',
		'进阶机器人',
		'专家机器人',
	],
	robot:{
		list:{
			// 1
			buy_sell:{
				name:'高抛低吸机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>用于在振荡行情中不断的低买高卖。同时可以做价格管控, 防止价格过高或过低。您可以设定一个卖出和买入的价格区间，盘口挂单价格超出区间后，机器人会执行对应的买入卖出。执行速度和数量根据参数设置确定。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">特殊参数说明：</span>
					<br/><br/>对手数量: 选中“对手数量”后，“数量”一栏将失效，下单时机器人会根据盘口散户挂单量来确认自己的交易量。
					<br/><br/>最大仓位: 用于管理当前币种最大持仓量，当机器人在操作中检测到您账户对应的币种余额大于等于最大仓位，机器人就会停止买入操作。
					<br/><br/>最小仓位: 用于管理当前币种最小持仓量，当机器人在操作中检测到您账户对应的币种余额小于等于最小仓位，机器人就会停止卖出操作。
					<br/><br/>工作条件: 选中“工作条件｜仅在被卡盘时工作”时，机器人仅在市场价格受阻且无法正常进行交易时才执行操作。未选中此项时，机器人只要价格达到设定范围就会立即开始交易。
					<br/><br/>提前挂单：选中“提前挂单”后，机器人将在设定的价格点提前挂好买卖单，为以确保价格达到设定值时可以尽快成交，从而提高策略的成功率。
					<br/><br/>允许自成交：选中“允许自成交”后，即使触发交易条件的单是自己账户的挂单，机器人仍会执行交易操作，从而优化账户的买卖单位置。

				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会进入工作队列，并在10分钟内开始工作。点击停止按钮，机器人会立即停止</p>
				`,
			},
			// 2
			iceberg:{
				name:'分批卖出机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>为您分批卖出总量比较大的虚拟币。</p>
				<br/><p style="color: #9198AA;">
				该机器人会根据设定的最低卖出价格，单次卖出数量和工作时间间隔（秒）进行币种抛售。您可以设定好每笔数量和笔数，两个值相乘的结果就是该机器人本次工作将会出售的总数, 为了保证出货行为隐蔽, 真实下单数量是设定值上下30%范围内的一个随机值。
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人立即开始工作。设定目标达成或余额不足时，机器人将终止工作</p>
				`,
			},
			// 3
			banzhuan:{
				name:'跨所对冲机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>为您进行跨交易所的差价套利。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>目标平台: 选择当前交易所需要和哪个交易所进行搬砖
					<br/><br/>目标交易对: 需要跟随的目标交易对
					<br/><br/>当前平台手续费: 单位是%，如手续费千1，填写0.1
					<br/><br/>目标平台手续费: 单位是%，如手续费千1，填写0.1
					<br/><br/>价格小数点位数: 价格小数点有几位填写几
					<br/><br/>数量小数点位数: 交易对挂单数量的精度
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				`,
			},
			// 4
			wangge:{
				name:'马丁策略量化机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>大环境上涨情况下, 盈利率很高的一种量化策略。每次运行会按配置开一单, 如果下跌到配置的幅度并出现一定的反弹, 就[加倍补仓]拉低持仓成本, 一旦价格上涨到持仓均价以上,达到设定的盈利比例, 就全部卖出, 并进入下一次循环。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>开仓金额: 首单开仓金额,单位是{0}
					<br/><br/>最大补仓次数: 首次开仓后, 总共加几次仓(不计首次开仓)
					<br/><br/>止盈幅度: 单位是%，最新价格比当前持仓均价高出的比例, 达到该数值, 不会立即止盈, 需要价格回落一定比例才止盈
					<br/><br/>止盈回调比例: 单位是%，达到止盈比例后, 价格回调多少才真正卖出持仓
					<br/><br/>补仓差价: 单位是%, 从上一次买入价格计算, 行情继续下跌多少会准备加仓, 达到该数值, 不会立即加仓, 需要价格反弹一定比例才买入
					<br/><br/>加仓回调比例: 单位是%, 达到加仓跌幅后, 价格反弹多少才真正加仓
					<br/><br/>首单加倍: 首单买入后, 如果价格在1%范围内波动, 会再追加买入一次, 金额等同首单金额, 且不算在加仓次数中
					<br/><br/>加仓倍数: 每次加仓的金额是上一次的多少倍
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止, 并撤销当前所有挂单</p>
				`,
			},
			// 5
			grid:{
				name:'网格策略机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>大环境震荡情况下, 盈利率很高的一种量化策略。机器人开启后会按设定的方向和网格挂号所有买入单, 并检测买入情况, 建仓后会按网格宽度挂好止盈单。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>首单金额: 第一手买入的金额
					<br/><br/>网格数量: 最大买入次数(含首次)
					<br/><br/>止盈比例: 单位是%，每一次买入后, 间隔多少挂止盈单
					<br/><br/>补仓间隔: 单位是%，买入后, 间隔多少挂下一个买入单
					<br/><br/>补仓间隔增幅: 补仓间隔的渐变幅度, 如果填大于1的数字X, 则下一次补仓间会放大X倍
					<br/><br/>补仓金额增幅: 每次补仓下单金额的渐变幅度, 如果填大于1的数字Y, 下一次补仓金额会放大Y倍
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止, 并撤销当前所有挂单</p>
				`,
			},
			// 6
			shua:{
				name:'成交量对敲机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>用于刷盘口成交量,提高盘口活跃度。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>对敲单量: 每次对敲的数量, 是一个范围,如100~300
					<br/><br/>每分钟次数: 代表每分钟对敲的次数范围, 如0~1
					<br/><br/>价格小数点位数: 代表价格的小数点精度, 如果币价是0.000366, 则填写6
					<br/><br/>数量小数点位数: 代表下单数量的小数点精度, 如希望下单数量精确到小数点后三位, 则填写3.
					<br/><br/>清理订单: 如果机器人订单和散户的挂单相撞会导致对敲单残留，如果选中会清理该机器人残留在盘口的订单(残留在盘口的挂单对资产保护和防套利有帮助作用)
					<br/><br/>安全模式: 在盘面出现恶意的干扰和逃离时, 该模式会用非常丰富的防护模块帮你对抗套利行为
					<br/><br/>先挂卖单: 开启后, 对敲会先挂卖出单再挂买入单 
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。遇到各种原因导致的下单失败或买卖盘口差价极小时，机器人将暂停工作。</p>
				`,
			},
			// 7
			budan_new:{
				name:'盘口管理机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>用于控制盘口买一卖一宽度, 买盘卖盘深度, 并且保持盘口挂单撤单活跃度,提高盘口活跃度。
				<br/>盘口管理机器人自带网格防御机制，当深度单成交后，盘口管理机器人会基于网格交易策略挂一个止盈单。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>买盘每档数量: 买盘每档维持的数量, 具体挂单数量是是该值的上下30%范围内取随机数
					<br/><br/>卖盘每档数量: 卖盘每档维持的数量, 具体挂单数量是是该值的上下30%范围内取随机数
					<br/><br/>盘口差价: 控制盘口时候买一卖一差价百分比
					<br/><br/>每档差价: 控盘时候买盘和卖盘每一档价格之间的差价百分比
					<br/><br/>价格小数点位数: 请填写该盘口价格精度, 如果是6位小数点,就填写6
					<br/><br/>维护档位数: 机器人维护的档位总数,默认是20档
					<br/><br/>数量小数点位数: 交易对挂单数量的精度
					<br/><br/>闪单: 会在盘口靠近买一卖一位置不断的挂撤单, 提高盘面活跃度，盘口的闪单有概率会被散户成交增加消耗
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				`
			},
			// 8
			hupan_buy:{
				name:'买盘护盘机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>帮助您在设定的价格范围里部署总量一定的支撑单,直到这些支撑单被成交,机器人停止。期间,机器人会随机的撤掉部分支撑和压力单,并修改数量后重新挂上, 模拟散户的盘口大额挂单。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>距离买一: 填写需要设置支撑和压力的价格范围
					<br/><br/>挂单总量: 设置支撑和压力的总量, 机器人会根据总量和您设置的档位数拆分出多个支撑(压力)单分开挂到盘口
					<br/><br/>档位数: 需要把上一个参数(挂单总量)拆成几个单进行挂单
					<br/><br/>价格小数点位数: 价格小数点有几位填写几
					<br/><br/>数量小数点位数: 交易对挂单数量的精度
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止,支撑单全部被成交, 机器人自动停止</p>
				`
			},
			// 9
			hupan_sell:{
				name:'卖盘护盘机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>帮助您在设定的价格范围里部署总量一定的支撑单,直到这些支撑单被成交,机器人停止。期间,机器人会随机的撤掉部分支撑和压力单,并修改数量后重新挂上, 模拟散户的盘口大额挂单。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>距离卖一: 填写需要设置支撑和压力的价格范围
					<br/><br/>挂单总量: 设置支撑和压力的总量, 机器人会根据总量和您设置的档位数拆分出多个支撑(压力)单分开挂到盘口
					<br/><br/>档位数: 需要把上一个参数(挂单总量)拆成几个单进行挂单
					<br/><br/>价格小数点位数: 价格小数点有几位填写几
					<br/><br/>数量小数点位数: 交易对挂单数量的精度
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止,支撑单全部被成交, 机器人自动停止</p>
				`
			},
			// 10
			push:{
				name:'趋势推动机器人',
				// <br/><br/>开始价位: 是机器人执行时候第一笔的挂单价格
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>趋势推动用于拉升或者砸盘。机器人启动后，会在设定时间内将价格推动至“目标价位”。当价格达到目标价位或到达结束时间时，机器人将停止操作。如果遇到散户干扰，机器人会自动调整推动速度。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>总消耗预算: 计划执行趋势的总金额
					<br/><br/>结束时间: 设定机器人到达目标价格的时间
					<br/><br/>目标价位: 资产期望的价格
					<br/><br/>价格小数点: 价格小数点有几位填写几
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人立即开始工作，遇到各种原因导致的下单失败或目标达成时，机器人自动停止</p>
				`
			},
			// 11
			hugevol:{
				name:'成交放量机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>在设定时间内, 机器人会在盘口卖一挂出大单并快速买回。 用于在短时间内做出成交量放量的信号, 引导散户关注该位置的支撑和抛售意图。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>Token成交量: 每分钟需要该机器人做出的成交量
					<br/><br/>工作时长: 工作几分钟
					<br/><br/>消耗预算: 和用户成交导致的资金消耗预算
					<br/><br/>价格小数点: 价格小数点有几位填写几
					<br/><br/>数量小数点位数: 交易对挂单数量的精度
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				`
			},
			// 12
			draw:{
				name:'画线机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>用于在散户参与度较低的时候机器人代操盘手做区间震荡。同时包含刷交易量和盘口布单机器人功能, 开启时会自动关闭当前盘口的盘口管理和对敲机器人。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>区间上限: 做区间波动时候最高价
					<br/><br/>区间下限: 做区间波动时候的最低价
					<br/><br/>盘口差价: 画线机器人控制盘口时候买一卖一差价百分比
					<br/><br/>每档差价: 控盘时候买盘和卖盘每一档价格之间的差价百分比
					<br/><br/>买盘每档数量: 买盘控盘时每档挂单数量的平均值, 实际挂单为该值上下波动30%的一个随机数
					<br/><br/>卖盘每档数量: 卖盘控盘时每档挂单数量的平均值, 实际挂单为该值上下波动30%的一个随机数
					<br/><br/>单次最大吃单: 价格推动到下一个价格节点的过程中如果遇到散户的单阻拦, 判断需要成交的总单量是否超过该值,如果不超过, 就会吃掉这批散户单, 继续进行价格波动, 否则会缩短画线距离直到小于单次最大吃单
					<br/><br/>价格小数点位数: 请填写该盘口价格精度, 如果是6位小数点,就填写6
					<br/><br/>单日刷单总量: 填写24小时刷单的总交易量(以币为数量单位), 自带对敲功能
					<br/><br/>跟随大盘: 选中后, 画线机器人将参考主流市场的涨跌趋势运行
					<br/><br/>价格序列：机器人将根据设定的价格和时间，绘制出理想的涨跌曲线
					<br/><br/>数量小数点位数: 交易对挂单数量的精度
					<br/><br/>闪单: 会在盘口靠近买一卖一位置不断的挂撤单, 提高盘面活跃度，盘口的闪单有概率会被散户成交增加消耗
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">风险控制：</span>如果散户参与交易,导致价格冲出设定的区间范围, 机器人会自动停止工作</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">特殊说明：</span>画线时遇到当前账户挂出的大单(订单金额>机器人参数买卖数量*5), 机器人会识别为操盘手人工设定的阻力位, 且不会去触碰这些订单</p>
				`
			},
			real_genpan:{
				name:'跟盘机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>可以让当前盘口跟随另一个交易所的同名盘口价格进行波动, 包含对敲和盘口管理功能, 开启时会自动关闭当前盘口的盘口管理和对敲机器人。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>模式: 标准模式下机器人将按目标盘价格进行跟踪, 对冲模式下机器人将通过跨所对冲的方式同时实现价格和流动性的同步, 可以有效防止跨所套利
					<br/><br/>目标盘口: 需要跟随的目标盘所在的交易所
					<br/><br/>目标交易对: 需要跟随的目标交易对
					<br/><br/>价格比例: 参数支持任意大于0的数字, 如果填写1%, 则当前盘跟随的价格是: 目标盘价格的1%, 如果希望当前盘完全按目标盘价格运行, 则设置为100%。在交易的币种相同但交易对不同时, 如果希望机器人自动获取两个交易对的价格比例, 请填写100%
					<br/><br/>盘口差价: 跟盘机器人控制盘口时候买一卖一差价百分比
					<br/><br/>每档差价: 控盘时候买盘和卖盘每一档价格之间的差价百分比
					<br/><br/>买盘每档数量: 买盘控盘时每档挂单数量的平均值, 实际挂单为该值上下波动30%的一个随机数
					<br/><br/>卖盘每档数量: 卖盘控盘时每档挂单数量的平均值, 实际挂单为该值上下波动30%的一个随机数
					<br/><br/>单次最大吃单: 价格推动时如果遇到散户的单阻拦, 判断单量是否超过该值,如果不超过, 就会吃掉该散户单, 继续进行价格波动
					<br/><br/>价格小数点位数: 请填写该盘口价格精度, 如果是6位小数点,就填写6
					<br/><br/>单日刷单总量: 填写24小时刷单的总交易量(以币为数量单位), 自带对敲功能
					<br/><br/>数量小数点位数: 交易对挂单数量的精度
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				`
			},
			// 14
			kongpan:{
				name:'高级盘口管理机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>专家级盘口管理机器人, 可用同时管理不同数量范围的深度单</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>消耗: 推动到目标价格的最大预算, 如果预算达到机器人会自动停止
					<br/><br/>基准价: 如果盘口没有挂单则按照基准价进行盘口初始化
					<br/><br/>盘口差价: 专家级盘口管理机器人控制盘口时候买一卖一差价(差价绝对值)
					<br/><br/>买(卖)单每档: 每档挂单的数量, 本机器人支持小中大3个层次的深度
					<br/><br/>每档差价: 每档单之间的差价绝对值
					<br/><br/>档位数量: 维护几档深度
					<br/><br/>价格小数点位数: 请填写该盘口价格精度, 如果是6位小数点,就填写6
					<br/><br/>数量小数点位数: 交易对挂单数量的精度
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				`
			},
			real_genpan_quick:{
				name:'主流币跟盘机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>可以让当前盘口跟随另一个交易所的同名盘口价格进行波动, 包含对敲和盘口管理功能, 开启时会自动关闭当前盘口的盘口管理和对敲机器人，自带跨平台对冲。工作条件： 盘口必须有深度，或至少有一个挂单。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>目标盘口: 需要跟随的目标盘所在的交易所
					<br/><br/>盘口差价: 跟盘机器人控制盘口时候买一卖一差价百分比
					<br/><br/>每档差价: 控盘时候买盘和卖盘每一档价格之间的差价百分比
					<br/><br/>买盘数量: 买盘控盘时每档挂单数量的平均值, 实际挂单为该值上下波动30%的一个随机数
					<br/><br/>卖盘数量: 卖盘控盘时每档挂单数量的平均值, 实际挂单为该值上下波动30%的一个随机数
					<br/><br/>价格小数点位数: 请填写该盘口价格精度, 如果是6位小数点,就填写6
					<br/><br/>单日刷单总量: 填写24小时刷单的总交易量(以币为数量单位), 自带对敲功能
					<br/><br/>数量小数点位数: 交易对挂单数量的精度
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				`
			},
			open_cex:{
				name:'开盘机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>用于在开盘的一瞬间打好开盘价，最高价，回落到预定的横盘价格。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>开盘时间: 预设交易开始的具体时间点（本地时间）
					<br/><br/>开盘价: 市场开盘时的第一个价格点
					<br/><br/>最高价: 开盘后瞬间达到的最高价
					<br/><br/>回落价: 最高价回落的目标价格
					<br/><br/>拉升回落下单量: 价格拉升和回落两笔交易的下单数量
					<br/><br/>价格小数点位数: 代表价格的小数点精度，这个参数请严格按照交易所提供的信息填写。如果填写的值不等于交易所提供的值可能导致下单错误及无法下单。如果下单价格是0.000366, 则填写6
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				`
			},
			shua_dex:{
				name:'Dex画线机器人',
				// , 最高600秒
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>用于在给定的价格范围中, 做随机波动, 并制造一些成交量,提高盘口活跃度并绘制dex品种交易k线。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>价格范围: 数字由低到高填写, 用来控制画线范围, 超出范围时, 机器人会暂停工作并继续监听价格
					<br/><br/>每次成交量: 数字由低到高填写,每次交易的交易量随机范围,范围可以设置大一些保证交易记录看起来更自然
					<br/><br/>间隔时间: 每次运行的时间间隔, 由于dex交易手续费和gas费用成本较高, 建议最低填写30秒
					<br/><br/>工作模式: 可用于配制机器人的特殊模式。自动分拨模式和普通模式区别就是会自己调配账户里的资金
					<br/><br/>批量下单模式：机器人会根据设定条件，强制使用多个钱包来执行每一笔订单。
					<br/><br/>趋势方向: 画线机器人工作时候默认会参考大行情的方向, 本参数可以进一步调整震荡方向
					<br/><br/>gas倍数: 调整操作gas费用的倍数，以优化交易速度和成功率
					<br/><br/>钱包分组: 选择钱包分组后，只有所选分组中的钱包会参与下单
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。遇到各种原因导致的下单失败或买卖盘口差价极小时，机器人将暂停工作</p>
				`
			},
			real_genpan_dex:{
				name:'Dex跟盘机器人',
				//  最大600, 
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>用于在去中心化交易所中，让价格跟着中心化交易所的价格走, 跟随的盘可以是任何盘, 注意控制好价格缩放参数, 保证价格在预计范围内运转。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>目标盘口: 需要跟随的目标盘所在的交易所
					<br/><br/>目标交易对: 需要跟随的目标交易对
					<br/><br/>目标盘价格缩放: 参数支持任意大于0的数字, 如果填写1%, 则当前盘跟随的价格是: 目标盘价格的1%, 如果希望当前盘完全按目标盘价格运行, 则设置为100%
					<br/><br/>间隔时间: 每次执行跟盘的时间间隔, 以秒为单位,建议不少于30秒, 因为dex手续费和gas费用较高, 不适合高频交易
					<br/><br/>单次最大交易量: 每次执行跟盘时最大吃单限制, 如果超出这个量, 说明跟随价格需要消耗过多的筹码, 机器人会停止工作并在日志报错, 该参数用于在不可预料的情况下保护您的资产安全
					<br/><br/>钱包分组: 选择钱包分组后，只有所选分组中的钱包会参与下单
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				`
			},
			buy_sell_dex:{
				name:'Dex高抛低吸',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>用于在振荡行情中不断的低买高卖。同时可以做价格管控, 防止价格过高或过低, 并在不断震荡中反复套利。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>价格高于: 当价格超出该设定价格, 机器人会进行卖出动作
					<br/><br/>价格低于: 当价格低于该设定价格, 机器人会进行买入动作
					<br/><br/>下单时间间隔: 没过多少秒, 高抛低吸工作一次, 请注意频率越高, 交易成本越高且对盘面价格管控越严格, 请根据需求适当配置, 建议30秒以上工作一次
					<br/><br/>每次下单数量: 触发高抛低吸价格后, 每次交易数量, 为了让交易记录看起来自然, 实际的下单数量时该数量的上下50%范围内的一个随机值
					<br/><br/>最大仓位: 资金安全参数, 当机器人检测到您账户对应的币种余额大于等于最大仓位，机器人就会停止买入操作。
					<br/><br/>最小仓位: 资金安全参数, 当机器人检测到您账户对应的币种余额小于等于最小仓位，机器人就会停止卖出操作。
					<br/><br/>gas倍数: 调整操作gas费用的倍数，以优化交易速度和成功率
					<br/><br/>钱包分组: 选择钱包分组后，只有所选分组中的钱包会参与下单
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				`
			},
			// 19
			frontrun_dex:{
				name:'Dex定时买入',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>机器人将按设定的时间买入一笔设定金额的订单。可能会由于价格波动过大导致买入价格不理想或买入失败</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>开盘时间: 预设交易开始的具体时间点
					<br/><br/>最高买入价: 可以接受的最高买入价
					<br/><br/>Gas: 执行交易操作所需支付的费用
					<br/><br/>交易金额：交易中涉及的资金量
					<br/><br/>钱包分组: 选择钱包分组后，只有所选分组中的钱包会参与下单
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				`
			},
			snipe_dex:{
				name:'Dex狙击新盘',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>dex中新创建的交易对，初始价格往往极低，会有很大的拉升机会。该机器人负责监控目标交易对的创建，一旦创建第一时间买入。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>目标合约: 填写新盘的合约地址
					<br/><br/>下单金额: 发现目标交易对创建后的买入量
					<br/><br/>盈利倍数: 预设狙击的盈利倍数，达到倍数时系统自动卖出
					<br/><br/>开盘时间: 预设新盘的开盘时间，到时系统自动开始狙击
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				`
			},
			// 21
			banzhuan_dex:{
				name:'DEX到CEX搬砖',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>帮助您从dex到cex进行搬砖套利。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>目标平台: 选择当前交易所需要和哪个交易所进行搬砖
					<br/><br/>当前平台手续费: 单位是%，如手续费千1，填写0.1
					<br/><br/>目标平台手续费: 单位是%，如手续费千1，填写0.1
					<br/><br/>价格小数点位数: 价格小数点有几位填写几
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				`
			},

			shua_nft:{
				name:'NFT画线机器人',
				//  最高600秒,
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>用于在给定的价格范围中, 做随机波动, 并制造一些成交量,提高盘口活跃度并绘制交易k线。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>价格范围: 数字由低到高填写, 用来控制画线范围, 超出范围时, 机器人会暂停工作并继续监听价格
					<br/><br/>每次成交量: 数字由低到高填写,每次交易的交易量随机范围,范围可以设置大一些保证交易记录看起来更自然
					<br/><br/>间隔时间: 每次运行的时间间隔, 由于交易手续费和gas费用成本较高, 建议最低填写30秒
					<br/><br/>趋势方向: 画线机器人工作时候默认会参考大行情的方向, 本参数可以进一步调整震荡方向
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。遇到各种原因导致的下单失败或买卖盘口差价极小时，机器人将暂停工作</p>
				`
			},
			buy_sell_nft:{
				name:'NFT高抛低吸',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>用于在振荡行情中不断的低买高卖。同时可以做价格管控, 防止价格过高或过低, 并在不断震荡中反复套利。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>价格高于: 当价格超出该设定价格, 机器人会进行卖出动作
					<br/><br/>价格低于: 当价格低于该设定价格, 机器人会进行买入动作
					<br/><br/>下单时间间隔: 没过多少秒, 高抛低吸工作一次, 请注意频率越高, 交易成本越高且对盘面价格管控越严格, 请根据需求适当配置, 建议30秒以上工作一次
					<br/><br/>每次下单数量: 触发高抛低吸价格后, 每次交易数量, 为了让交易记录看起来自然, 实际的下单数量时该数量的上下50%范围内的一个随机值
					<br/><br/>最大仓位: 资金安全参数, 当机器人检测到您账户对应的币种余额大于等于最大仓位，机器人就会停止买入操作
					<br/><br/>最小仓位: 资金安全参数, 当机器人检测到您账户对应的币种余额小于等于最小仓位，机器人就会停止卖出操作
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				`
			},
			
			// 24
			defence:{
				name:'防御机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>用于在盘口有套利团队, 且套利情况极其严重时候进行防御反击, 但请注意这个机器人会将之前盘口的所有挂单都清除</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>最小下单量: 该参数填写交易所设定的最低的挂单数量
					<br/><br/>卡盘数量: 该参数填写卡盘单的挂单数量, 可以尽量小, 比如1, 该数字可以比最小下单量更小
					<br/><br/>每次吃单数量: 卡盘成功后每次尝试挂单的数量
					<br/><br/>币上限: 当币资产大于等于这个数字时, 机器人会进入出货状态
					<br/><br/>币下限: 当币资产小于等于这个数字时, 机器人会进入低吸状态
					<br/><br/>价格小数点位数: 交易对价格的小数点精度
					<br/><br/>数量小数点位数: 交易对挂单数量的精度
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。点击停止按钮，机器人会立刻停止</p>
				`,
			},
			// 25 分批卖出机器人
			iceberg_dex:{
				name:'Dex分批卖出机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>为您分批卖出总量比较大的虚拟币</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>最低价格: 用于设置卖出操作的最低价格门槛，当市场价格低于该价格时，机器人将不会执行卖出操作。
					<br/><br/>间隔: 两次卖出操作之间的时间间隔，单位为秒。
					<br/><br/>每次数量: 每次执行卖出操作时计划卖出的数量。为保持抛售行为的隐蔽，真实下单数量会在该设定值上下 10% 的范围内随机波动。
					<br/><br/>次数: 本次卖出任务的总执行次数。
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人立即开始工作。设定目标达成或余额不足时，机器人将终止工作</p>
				`,
			},
			// 26 Dex刷量机器人
			shua_dex2:{
				name:'Dex刷量机器人',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>控制大量钱包刷量交易, 买卖订单会同时进行 ,为交易对增加活跃钱包数和成交数</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>价格范围: 数字由低到高填写, 用来控制画线范围, 超出范围时, 机器人会暂停工作并继续监听价格
					<br/><br/>每次成交量: 数字由低到高填写,每次交易的交易量随机范围,范围可以设置大一些保证交易记录看起来更自然
					<br/><br/>高频模式：机器人将通过快速、频繁的买卖交易来增加交易量和市场活跃度
					<br/><br/>间隔时间: 每次运行的时间间隔, 由于dex交易手续费和gas费用成本较高, 建议最低填写30秒
					<br/><br/>工作模式: 可用于配制机器人的特殊模式, 其中批量下单模式会根据设定的价格顺序进行波动；自动分拨模式和普通模式区别就是会自己调配账户里的资金
					<br/><br/>新钱包模式：机器人将通过创建和使用多个新的钱包地址来增加交易中的 maker 订单数量，提升市场活跃度。（目前该模式仅支持 Solana 链）
					<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;● 启动时：启动机器人后，将进入 10 分钟的初始化阶段，待初始化完成后方可开始正式交易。
					<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;● 停止时：机器人停止后会自动进行归集操作，您可以在日志中查看归集进度。归集完成后，才能再次启动机器人。
					<br/><br/>gas倍数: 调整操作gas费用的倍数，以优化交易速度和成功率
					<br/><br/>钱包分组: 选择钱包分组后，只有所选分组中的钱包会参与下单
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动条件：</span>买盘和卖盘至少要有一张挂单</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。遇到各种原因导致的下单失败或买卖盘口差价极小时，机器人将暂停工作</p>
				`,
			},
			pump_sniper:{
				name:'PUMP抢买',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>通过设置抢买token总量和监控指定发币地址的发币动作，并进行抢买操作。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>抢买分组: 选择抢买分组后，只有所选分组中的钱包会参与下单
					<br/><br/>抢买token总量: 机器人在抢买分组中的所有钱包预计抢买的token总量
					<br/><br/>监控地址: 机器人会监控该地址的PUMP发币动作，一旦触发机器人会立即进行抢买
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。</p>
				`,
			},
			pump_sellout:{
				name:'PUMP清仓',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>通过设置钱包分组和最低卖出价，机器人将在市场达到预定卖出价格时进行清仓操作。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>钱包分组: 选择钱包分组后，只有所选分组中的钱包会参与清仓操作
					<br/><br/>最低卖出价: 机器人将在市场价格达到或超过此卖出价格时，开始清仓操作
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。</p>
				`,
			},
			pump_batch_buy:{
				name:'PUMP批量买入',
				html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>通过设置批量买入的token地址、钱包分组及买入总量，实现对多个钱包的批量买入操作。</p>
				<br/><p style="color: #9198AA;">
					<span style="color: #E65B1B;">参数说明：</span>
					<br/><br/>钱包分组: 选择钱包分组后，只有所选分组中的钱包会参与下单
					<br/><br/>买入token总量: 机器人在钱包分组中的所有钱包预计买入的token总量
					<br/><br/>token地址: 需要批量买入的token合约地址，机器人会根据该地址执行买入操作
				</p>
				<br/><p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>点击启动按钮，机器人会立即开始工作。</p>
				`,
			},
			
		},
		showRunning:'仅显示运行中',
		addrobot:'添加机器人',
		log:'机器人日志',
		// 1 buy_sell
		higherthan:'价格高于',
		sellout:'卖出',
		below:'价格低于',
		purchase:'买入',
		interval:'下单时间间隔',
		second:'秒',
		per:'每次下单数量',
		opponents:'对手数量',
		maximum:'最大仓位',
		maximumPH1:'最大token余额',
		maximumTip:'买入时,最大持仓限制',
		minimum:'最小仓位',
		minimumPH1:'最小token余额',
		minimumTip:'卖出时,最小持仓限制',
		working:'工作条件',
		chuck:'仅在被卡盘时工作',
		limitOrder:'提前挂单',
		tradeSelfOrder:'允许自成交',
		estimatedVol24:'预估24h成交量',
		estimatedAmount:'预估总成交额',
		buySellBotTip:'账户余额大于【最大仓位】时停止买入，小于【最小仓位】时停止卖出',
		// 2
		floorPrice:'最低价格',
		interval2:'间隔',
		interval2PH:'间隔多久卖一次',
		quantityPerTime:'每次数量',
		quantityPerTimePH:'每次卖出的数量',
		frequency:'次数',
		frequencyPH:'总共执行多少次',
		// 3 banzhuan
		targetExchange:'目标平台',
		currentCharge:'当前平台手续费',
		targetCharge:'目标平台手续费',
		priceDecimal:'价格小数点位数',
		priceDecimalPH:'请输入价格的小数点精度, 如果币价是0.000366, 则填写6',
		quantityDecimal:'数量小数点位数',
		quantityDecimalPH:'请输入下单数量的小数点精度, 如希望下单数量精确到小数点后三位, 则填写3',
		banzhuanBotTip:'{robot}在两个交易对出现价差且价差大于手续费时运行，在高价一侧卖出、低价一侧买入',
		// 4
		openingAmount:'开仓金额',
		repTimes:'补仓次数',
		repTimesPH:'最多补仓几次',
		interferenceRange:'止盈幅度',
		repDifferences:'补仓差价',
		ci:'次',
		recommend:'推荐值',
		
		stopBackRatio:'止盈回调比例',
		addBackRatio:'加仓回调比例',
		firstDouble:'首单加倍',
		addMultiple:'加仓倍数',

		firstAmount:'首单金额',
		// addNum:'加仓次数',
		stopRatio:'止盈比例',
		// declineRatio:'加仓跌幅',
		// 5
		priceRange:'价格范围',
		priceRangePH1:'最低价',
		priceRangePH2:'最高价',
		grids:'网格数量',
		perGear:'每档数量',
		maintain:'维持买单数',
		stopPrice:'止损价格',
		// more:'做多',
		// short:'做空',

		addInterval:'补仓间隔',
		addIntervalIncrease:'补仓间隔增幅',
		addAmountIncrease:'补仓金额增幅',
		// 6 shua
		counterQuantity:'对敲单量',
		perMinute:'每分钟',
		clearOrder:'清理订单',
		advancedProtection:'安全模式',
		sellOrderFirst:'先卖挂单',
		latestTransactionPriceErr:'由于交易所接口不稳定，无法获取最新成交价，预估总成交额暂时不可用，但这不影响机器人正常运行',
		securityCloseLabel:'安全模块关闭',
		securityCloseTip:'当前机器人设置的每分钟频率已超过60次（即高速模式），将自动关闭所有安全模块',
		// 7
		orderPerNum:'买盘每档数量',
		orderPerNumPH:'请输入盘口挂单的买盘每档数量',
		sellPerNum:'卖盘每档数量',
		sellPerNumPH:'请输入盘口挂单的卖盘每档数量',
		difference:'盘口差价',
		differencePH:'盘口宽度',
		perDifference:'每档差价',
		perDifferencePH1:'数值, 建议设为盘口差价的一半',
		perDifferencePH:'差价数额',
		maintenance:'维护档位数',
		maintenanceVal:'买卖各{num}个',
		buyNumRang:'买盘数量范围',
		sellNumRang:'卖盘数量范围',
		estimatedFunds:'预计占用资金',
		buyOrder:'买盘',
		sellOrder:'卖盘',
		pankouManageTip:'机器人将会自动管理该挂单数量范围内的挂单，您的挂单若在此范围内，可能会被撤销',
		pankouReadFail:'无法读取盘口数据，机器人无法正常工作',
		positionTip:'用户最高的买单将挂在{buyPriceTarget}, 最低的卖单将挂在{sellPriceTarget}<br/><br/>确认要启动机器人吗？',
		flashOrder:'闪单',
		// 8 9 hupan
		buyPriceRange:'距离买一',
		sellPriceRange:'距离卖一',
		protective:'挂单总量',
		protectivePH:'挂单总的币数',
		gear:'档位数',
		gearPH:'分几档挂护盘单',
		priceTickPH8:'价格小数点位数',
		numTickPH8:'下单数量的小数点位数',
		hupanBotTip:'当您设定的护盘单全部成交时，机器人将停止工作',
		// 10 push
		buyBtn:'买入',
		sellBtn:'卖出',
		plan:'总消耗预算',
		singleBudget:'单笔预算',
		// intervalRange:'挂单时间间隔范围(秒)',
		random:'随机',
		// perInterval:'每次推动价格间隔',
		endTime:'结束时间',
		startPrice:'开始价位',
		targetPrice:'目标价位',
		pushBotTip:'工作中超出预算时，机器人将提前停止',
		// 11
		tradingVolume:'Token成交量',
		workingHours:'工作时长',
		splitBudget:'消耗预算',
		minute:'分钟',
		minutes:'分钟',
		// 12 draw 画线
		upperLimit:'区间上限',
		lowerLimit:'区间下限',
		buy:'买盘数量',
		offers:'卖盘数量',
		singleMax:'单次最大吃单',
		singleMaxPH:'单次吃散户单量上限',
		swiping:'每日刷单总量',
		swipingPH:'总成交币数',
		indexFollow:'跟随大盘',
		limitTip:'区间上限必须大于等于区间下限',
		drawBotTip:'机器人启动后，若最新成交价超出设置价格，画线模块将会停止，其他模块继续工作',
		buySellCost:'买盘挂单预计消耗{buyTotalSum}{yenUnit2}，卖盘挂单预计消耗{sellTotalSum}{yenUnit1}',
		// 13 real_genpan
		standard:'标准模式',
		rebalance:'对冲模式',
		targetDisk:'目标盘口',
		scaling2:'价格比例',
		realGenpanBotTip:'{robot}跟随的是盘口的位置，而非最新成交价格',
		// 14
		budget:'消耗预算',
		budgetPH:'最大消耗量',
		target:'目标价',
		benchmarkPrice:'基准价',
		paySmall:'买单每档(小单)',
		sellSmall:'卖单每档(小单)',
		gears:'档位数量',
		gearsPH:'共维护几档挂单',
		payMedium:'买单每档(中单)',
		sellMedium:'卖单每档(中单)',
		payLarge:'买单每档(大单)',
		sellLarge:'卖单每档(大单)',
		// open_cex
		openPrice:'开盘价',
		topPrice:'最高价',
		downPrice:'回落价',
		pullOrdersUpAndDown:'拉升回落下单量',
		// 16 shua_dex
		transaction:'每次成交量',
		intervalTime:'间隔时间',
		direction:'趋势方向',
		oscillation:'随机震荡',
		rise:'上涨',
		fall:'下跌',
		operationMode:'工作模式',
		normalMode:'普通模式',
		batchOrderMode:'批量下单模式',
		unlimitedWalletMode:'自动分拨模式',
		newWalletMode:'新钱包模式',
		customMode:'价格序列',
		sequentialPlan:'序列方案',
		systemPlan:'系统方案',
		customPlan:'自定义方案',
		sequential:'序列',
		sequenceNaming:'请为您的序列命名，若名称相同则会覆盖对应名称的序列方案',
		sequenceNamingOverride:'该序列名称已存在，是否覆盖已有的{name}序列方案',//请为您的序列命名，若希望覆盖原序列可不更改名称
		sequentialNameEmpty:'请输入序列名称',
		position:'点位',
		newPosition:'添加新点位',
		timePoint:'时间点',
		timeExpiredErr:'您设置点位的时间已失效，请重新设置',
		// priceGapErr:'您设置点位的价格超出了画线范围，是否确认继续？',
		priceGapErr2:'您设置点位的价格超出了价格范围的{num}倍！请重新设置',
		yourPrice:'您输入的点位价格：',
		yourPriceRange:'您输入的价格范围：',
		priceRangeWarnLabel:'价格范围提醒',
		priceRangeWarn:'您设置的价格范围没有包含最后成交价{price}，机器人无法正常运行，请确认价格范围是否正确',
		priceUnitWarnLabel:'单位提醒',
		priceUnitWarn:'请您再次确认您填写的价格参照的计价单位是否有误，可能存在与预期单位不符的情况',
		gasMultiplier:'gas倍数',
		gasMultiplierTip:'gas倍数越高，交易成交率越高，0倍代表自适应gas',
		gasMultiplierTip2:'gas倍数越高，交易成交率越高',
		automatic:'自动',
		shuaDexBotTip:'价格超出设定范围后机器人将暂停工作请注意工作频率以及gas消耗',
		shuaDexBotTip2:'如果频率过高，可能会导致过多的gas费用',
		shuaDexBotTipSol1:'请注意，<span style="color:#E65B1B;">预计每小时消耗{num}{yenUnit2}</span>',
		shuaDexBotTipSol2:'请注意，<span style="color:#E65B1B;">预计每小时最低消耗{num1}{yenUnit2}，最高消耗{num2}{yenUnit2}</span>',
		// 17
		targetPair:'目标交易对',
		scaling:'目标盘价格缩放',
		transactionMax:'单次最大交易量',
		transactionMaxPH:'每次最大买卖数量',
		// 19 frontrun_dex
		trigger:'触发交易量',
		everyPurchase:'每次买入',
		autoSell:'是否自动卖出',
		isAutoSell:'自动卖出',
		
		slippage:'最高买入价',
		gas:'Gas',
		transactionAmount:'交易金额',
		frontrunDexBotTip:'只调用资金钱包进行单笔交易，开盘时价格波动激烈可能会交易失败',
		// 20
		contract:'目标合约',
		orderAmount:'下单金额',
		multiple:'盈利倍数',
		multiplePH:'如:填写1.2代表盈利20%',
		openingTime:'开盘时间',
		openingTimePH:'请设定开盘时间',
		deduction:'是否扣税',
		times:'倍',
		taxDeduction:'扣税',
		noTaxDeduction:'不扣税',
		// 24 防御机器人
		orderMin:'最小下单量',
		kaNum:'卡盘数量',
		eatNum:'每次吃单数量',
		maxNum:'币上限',
		minNum:'币下限',
		priceTick:'价格小数点位数',
		numTick:'数量小数点位数',
		maxNumContraryTip:'币上限必须大于等于币下限',
		// 25 dex分批卖出
		totalShipments:'出货总量',
		// 26 dex刷量
		HighFrequency:'高频模式',
		
		walletGroup:'钱包分组',
		// PUMP抢买
		buyGroup:'抢买分组',
		totalPurchaseAmount:'抢买token总量',
		monitoredAddress:'监控地址',
		// PUMP清仓
		minSellPrice:'最低卖出价',
		// PUMP批量买入 pump_batch_buy
		totalBuyInTokenAmount:'买入token总量',
		tokenAddress:'token地址',

		success:'机器人添加成功',
		sub:'您可通过操盘机器人列表查看您添加的机器人',
		parameter:'参数设置',
		current:'当前状态',
		running:'机器人运行中',
		stopped:'机器人已停止',
		robotEmpty:'您未添加过任何机器人，去添加',
		robotEmpty2:'当前暂无运行中机器人，去开启',
		downLoadTip:'即将下载满足条件的日志，大约需要几分钟，导出文件将发送到您的邮箱',
	},

	// 工具
	tools:{
		check:{
			name:'批量下买单',
			sub:'帮您在某个价格范围内批量挂单',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>帮您在某个价格范围内批量挂单。</p>
				<br/>
				<p style="color: #9198AA;">根据设定的价格范围和订单总数，机器人会将设定的价格范围划分成若干档(数量等于订单总数), 然后在每档价格上挂单, 挂单具体数量取挂数量范围内的随机数量。“价格范围”需要填写具体价格，注意先写范围的下限,再填范围上限；“单量”确定每一张买卖单数量的随机范围; ”总单数“代表一次工作挂多少张单子</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>参数填写完成后点击“提交”按钮，机器人会立刻工作,工作完即停止</p> 
			`,
		},
		sale:{
			name:'批量下卖单',
			sub:'帮您在某个价格范围内批量挂单',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>帮您在某个价格范围内批量挂单。</p>
				<br/>
				<p style="color: #9198AA;">根据设定的价格范围和订单总数，机器人会将设定的价格范围划分成若干档(数量等于订单总数), 然后在每档价格上挂单, 挂单具体数量取挂数量范围内的随机数量。“价格范围”需要填写具体价格，注意先写范围的下限,再填范围上限；“单量”确定每一张买卖单数量的随机范围; ”总单数“代表一次工作挂多少张单子</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>参数填写完成后点击”提交“按钮，机器人会立刻工作,工作完即停止</p> 
			`,
		},
		revoke:{
			name:'批量撤单',
			sub:'帮您撤销某个价格范围所有挂单',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>帮您撤销某个价格范围所有挂单。</p>
				<br/>
				<p style="color: #9198AA;">根据设定的价格范围撤销掉该范围内所有自己的挂单。“价格范围”需要填写具体价格，注意先写范围的下限,再填范围上限</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>参数填写完成后点击”提交“按钮，机器人会立刻工作,工作完即停止</p> 
			`,
		},
		calculate:{
			name:'Dex交易量测算',
			sub:'可以方便的计算出价格涨跌到指定价格，所需的交易量，或者输入一个交易量可以知道价格会被推到哪里',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>通过该工具，您可以方便的计算出价格涨跌到指定价格，所需的交易量，或者输入一个交易量可以知道价格会被推到哪里</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">参数说明：</span>
				<br/>目标价：输入预计要到的目标价格，输入该参数交易量参数可以不填，工具会计算出需要多少交易量
				<br/>所需交易量: 输入预计的交易量，输入该参数价格参数可以不填，工具会算出价格会被推动到什么位置</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">启动和停止：</span>参数填写完成后点击”提交“按钮，机器人会立刻工作,工作完即停止</p> 
			`,
		},
		transaction:{
			name:'Dex批量交易',
			sub:'可以控制dex所有交易钱包批量进行买入和卖出操作',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>通过该工具，可以控制dex所有交易钱包批量进行买入和卖出操作</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">参数说明：</span>
				<br/>钱包数量：输入想要下单的钱包数量
				<br/>交易量：输入总的交易量，对于交易对A-B， 买入时该参数代表要花费多少B， 卖出时该参数代表要卖出多少A
				<br/>浮动范围：可以根据浮动比控制每个钱包的下单数量。下单数量范围：【(下单总量/下单钱包数)*最低浮动比 ~ (下单总量/下单钱包数)*最高浮动比】，例如浮动比40%，下单数量范围：【(下单总量/下单钱包数)*60% ~ (下单总量/下单钱包数)*140%】
				<br/>交易类型：买、卖
				<br/>间隔时间：批量下单每一单间隔的秒数
				<br/>gas倍数：调整操作gas费用的倍数，以优化交易速度和成功率
				<br/>滑点：交易执行价格与预期价格之间的差异
				<br/>钱包分组：选择钱包分组后，只有所选分组中的钱包会参与下单
				</p>
			`,
		},
		// balance:{
		// 	name:'Dex查询交易钱包余额',
		// 	sub:'方便的计算所有交易钱包里资产余额',
		// 	html:`
		// 		<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>通过该工具，您可以方便的计算所有交易钱包里资产余额</p>
		// 	`,
		// },
		balance2:{
			name:'Dex查询/刷新交易钱包余额',
			sub:'方便的计算所有交易钱包里资产余额以及手动刷新交易钱包余额信息',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>通过该工具，您可以方便的计算所有交易钱包里资产余额以及余额异常时可以手动去刷新交易钱包余额信息</p>
			`,
		},
		wallet:{
			// name:'Dex创建交易钱包',
			name:'创建交易钱包',
			sub:'可以批量创建下单用的交易钱包',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>通过该工具，可以批量创建下单用的交易钱包</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">参数说明：</span>
				<br/>链名：数据需要创建钱包的链, 如 BSC, MATIC, OEC, HECO等
				<br/>钱包数量：填写数字, 代表要创建多少个钱包
				<br/>交易限制：无限制（钱包参与下单买或者卖随机）、只买（钱包只能参与下单买入）、只卖（钱包只能参与下单卖出）</p>
			`,
		},
		distribution:{
			// name:'Dex分拨资金',
			name:'分拨资金',
			sub:'可以从绑定好的资金钱包分拨指定的资产到通过交易钱包创建工具创建出的交易钱包里',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>通过该工具，可以从绑定好的资金钱包分拨指定的资产到通过交易钱包创建工具创建出的交易钱包里</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">参数说明：</span>
				<br/>链名：数据需要创建钱包的链, 如 BSC, MATIC, OEC, HECO等
				<br/>币种：需要分拨的资产名称简写, 如USDT, BNB
				<br/>总数量: 需要分拨的资产的总数量
				<br/>分拨策略: 
				<br/>&nbsp;&nbsp;1-随机分配（随机占比分配到每个交易钱包，可能分配浮动差距会大）
				<br/>&nbsp;&nbsp;2-平均浮动（按您输入的浮动范围，分拨到每个钱包的资金在平均值上下浮动分拨，0%是平均分拨，>=80%按随机策略分拨）
				<br/>&nbsp;&nbsp;3-条件分拨（按您输入的条件筛选要分拨的钱包并分拨）
				</p>
			`,
			// <br/>钱包少于:设置需要接受分拨的钱包的资产条件
			// <br/>浮动范围:每个钱包平均分拨
		},
		pool:{
			// name:'Dex归集资金',
			name:'归集资金',
			sub:'可以把通过交易钱包创建工具创建出的交易钱包里的指定资产都汇集到绑定好的资金钱包中',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>通过该工具，可以把通过交易钱包创建工具创建出的交易钱包里的指定资产都汇集到绑定好的资金钱包中</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">参数说明：</span>
				<br/>链名：数据需要创建钱包的链, 如 BSC, MATIC, OEC, HECO等
				<br/>币种：需要归集的资产名称简写, 如USDT, BNB
				<br/>总数量：需要归集的资产的总数量</p>
			`,
		},
		empower:{
			name:'Dex钱包授权',
			name2:'新币授权',
			sub:'可以给资金钱包和交易钱包进行某个代币授权交易',
			html:`
				<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>通过该工具，可以给资金钱包和交易钱包进行某个代币授权交易</p>
				<br/>
				<p style="color: #9198AA;"><span style="color: #E65B1B;">参数说明：</span>
				<br/>授权代币：输入代币token值</p>
			`,
		},
		addToken:{
			name:'新增Token',
			sub:'给系统新增一个需要交易的合约',
			html:`
			<p style="color: #9198AA;"><span style="color: #E65B1B;">使用场景：</span>通过该工具，可以给系统新增一个需要交易的合约</p>
			<br/>
			<p style="color: #9198AA;"><span style="color: #E65B1B;">参数说明：</span>
			<br/>Token名：Token的名称简写。这通常是一个缩写，如BTC、ETH等。
			<br/>合约地址：Token的合约地址
			<br/>Decimals：表示Token可以被分割到多少小数点后
			<br/>手续费：交易的手续费
			<br/>计价代币：在闪电系统中，计价代币通常指的是稳定币（如USDT、USDC等），这些代币的价格较为稳定，广泛用于交易和计算中。
			</p>
		`,
		},
		holderList:{
			name:'散户持仓列表',
			sub:'散户持仓列表展示持仓信息，并支持备注编辑',
		},
		positionAddress:'持仓地址',
		positionQuantity:'持仓数量',
		positionPercentage:'持仓比例',
		lastUpdated:'更新时间',
		remark:'备注',
		editRemark:'编辑备注',
		viewRemark:'查看备注',

		token:'Token名',
		tokenPH:'token的名称简写',
		address:'合约地址',
		decimals:'Decimals',
		fee:'手续费',
		addPair:'添加Pair',
		quoteToken:'计价代币',
		pairAddress:'Pair地址',
		formatErr:'{token}格式错误，请检查是否存在符号',
		solPairErrTip:'Solana 链必须添加Pair信息，不能为空',
		// dex钱包管理
		manage:{
			name:'Dex钱包管理',
			sub:'Dex创建交易钱包、Dex分拨资金、Dex归集资金',
		},
		// dex交易钱包刷新
		refresh:{
			name:'刷新余额',
			sub:'手动刷新交易钱包余额信息',
		},
		// 导入交易钱包
		importWallet:{
			name:'导入交易钱包',
		},
		// nft钱包管理
		manageNFT:{
			name:'NFT钱包管理',
			sub:'创建钱包、分拨资金、归集资金',
		},
	},
	
	// 数据报表
	data:{
		recent7:'近7天',
		recent30:'近30天',
		allocation:'资金分配',
		dailyTrading:'每日交易量',
		gas:'手续费',
		buy:'买单',
		sell:'卖单',
		curve:'资产曲线',
		nftWait:'暂不支持NFT，敬请期待！',
		nftWait2:'暂不支持NFT交易所数据统计，敬请期待！',
		endTime:'图表数据截止至',
		export:'导出交易明细',
		dailyRecord:'当日数据',
		historyRecord:'历史数据',
		snapshot:'资产快照',
		curve2:'资产变动曲线',
	},
	// 资产报表
	property:{
		propertyEmpty:'暂无资产报表',
		selectTime:'时间范围',
		option1:'近1个月',
		option2:'近3个月',
		option3:'近6个月',
		
	},

	// 报警系统
	alarmSystem:{
		addAlarm:'新建报警器',
		telegram:'绑定电报',
		parameter:'参数设置',
		currentState:'当前状态',
		operation:'报警器运行中',
		stopped:'报警器已停止',
		noData:'暂无添加报警系统',
		priceThan:'价格高于',
		priceBelow:'价格低于',
		less:'币少于',
		mutation:'价格突变幅度',
		conditions:'盘口出现套利机器人',
		colseRobot:'是否关闭机器人',
		addTrigger:'新建触发报警器',
		editTrigger:'编辑触发报警器',

		activateAlarm:'开启报警系统',
		alarmSystem:'报警系统',
		title1:'根据币价格开启报警系统',
		title2:'根据资产开启报警系统',
		optionalTip:'以下内容可选填',
		priceThan2:'币价格高于多少？',
		priceBelow2:'币价格低于多少？',
		less2:'资产{token}币少于多少？',
		mutation2:'币价格突变服务是%多少？',
		conditions2:'是否在盘口出现套利机器人时开启报警系统？',
		colseRobot2:'是否在出现报警时关闭机器人？',
	},

	// 续费开通
	renew:{
		account:'个人账户',
		balance:'钱包余额',
		balanceDetail:'余额明细',
		vipNow:'当前套餐',
		remaining:'剩余{time}',
		expired:'已到期',
		remainingMonth:'个月',
		remainingDay:'天',
		remainingMonths:'个月',
		remainingDays:'天',
		contact:'联系客服',
		
		opentime:'开通时长',
		month:'月',
		individual:'个',
		month2:'个月',
		openings:'盘口数量',
		activate:'立即开通',
		switchPackage:'切换套餐',
		confirmation1:'续费信息确认',
		confirmation2:'切换套餐信息确认',
		grade:'账号等级',
		validity:'充值后有效期',
		openingsTip:'（盘口数量代表同时可以在几个盘开启机器人）',
		cost:'费用',
		activityCost:'活动价：',
		stillNeed:'还需支付',
		refund:'退款',
		refundTip:'退款将退回您的系统钱包',//温馨提示：
		rechargeNeed:'还需充值',
		rechargePay:'充值支付',
		rechargePayTip1:"【注:点击'充值支付'后系统将暂时锁定您的钱包，检测到您的充值动作后自动续费，然后解锁您的钱包。（若您想中断您的当前操作并解除钱包锁定，请您联系客服处理！）】",//充值支付提示-续费
		rechargePayTip2:"【注:点击'充值支付'后系统将暂时锁定您的钱包，检测到您的充值动作后自动切换您所修改的套餐，然后解锁您的钱包。（若您想中断您的当前操作并解除钱包锁定，请您联系客服处理！）】",//充值支付提示-切换套餐

		package:'套餐权益',
		include:'包含',
		origin:'原价 ',

		subBalance:' 余额',
		// 充值
		rechargeType:'充值链',
		rechargeAddress:'充值地址',
		copyAddress:'复制地址',
		tip:'温馨提示',
		rechargeTip1:'请确认只把USDT({type})发送到这个地址。如果把其他的币发送到这个地址，将无法找回且不会赔付。在区块确认后将确认为充币成功。',
		// rechargeTip2:'闪电钱包暂不支持充值功能，给您带来的不便请谅解，如需提供帮助请您联系商务客服。',
		renewRecord:'充值续费记录',
		only5:'（仅显示近5条）',
		only10:'（仅显示近10条）',
		renewAddress:'地址',
		renewAmount:'金额',
		renewTime:'时间',
		renewSource:'链',
		renewState:'状态',
		renewSuc:'成功',
		endTime:'账户到期时间',
		blockQuery:'区块查询',
		// 提现
		address:' 地址',
		// total:'总余额',
		min:'最低提现金额',
		service:'手续费',
		withdrawalType:'钱包',
		withdrawalChain:'提现链',
		withdrawalAmount:'提现金额',
		withdrawalHolder:'最低50USDT,请填写数字',
		withdrawalAddress:'提现地址',
		withdrawalTip1:'请确保填写正确的USDT({type})地址。如果你输入的地址不是你的提币地址，或者有一些必要的参数没有填写导致损失LIGHT将不会赔付。为了保障你的资金安全，大额提币需要人工审核，通常审核时间为10-30min之间。',
		// withdrawalTip2:'闪电钱包暂不支持提现功能，给您带来的不便请谅解，如需提供帮助请您联系商务客服。',
		withdrawalTip2:'请确保填写正确的USDT提现地址。如果你输入的地址不是你的提币地址，或者有一些必要的参数没有填写导致损失LIGHT将不会赔付。为了保障你的资金安全，提币需要人工审核，通常审核时间为10-30min之间。',
		withdrawalRecord:'提现记录',
		withdrawalTime:'提现时间',
		currentState:'当前状态',
	},
	// 续费时长
	renewTime:[
		'月付',
		'季度付',
		'半年付',
		'年付'
	],
	renewTime2:[
		'月付',
		'季度付 (8折)',
		'半年付 (7折)',
		'年付 (6折)'
	],
	// 充值续费提现状态
	state:[
		'后台续费成功',//0
		'续费支付中',//1
		'续费成功',//2
		'续费失败',//3
		'修改套餐支付中',//4
		'修改套餐成功',//5
		'修改套餐失败',//6
		'提现申请中',//7
		'提现成功',//8
		'提现失败',//9
		'提现审核通过',//10
		'充值归集中',//11
		'充值成功',//12
		'充值失败',//13
	],
	// 等级
	level:[
		'个人版',
		'企业版',
		'旗舰版',
		'DEX版',
		'NFT版',
		'合约版',
	],

	// 用户中心
	user:{
		data:'账号资料',
		userName:'用户名',
		lv:'账户等级',
		upORren:'升级/续费',
		autoRenew:'自动续费',
		autoRenewTip:'自动续费：当账号有效期剩余3天且闪电钱包余额满足续费费用，系统将自动帮您续费，无需您手动操作。',
		help:'新手引导',
		security:'安全设置',
		modifyNum:'修改号码',
		changePwd:'修改密码',
		bindEmail:'绑定邮箱',
		changeEmail:'修改邮箱',
		safeLogin:'安全登录',
		multiDeviceLogin:'多设备登录',
		ipVer:'IP验证',
		ipRecommend:'不推荐关闭',
		loginHistory:'登录记录',
		loginHistoryTitle:'历史登录记录',
		viewRecords:'查看记录',
		loginTime:'登录时间',
		
		language:'语言',
		changeLanguage:'修改语言',

		model:'模式',
		dark:'夜间模式',
		light:'日间模式',
	},
	
	// 邀请好友
	invite:{
		code:'邀请码',
		link:'邀请链接',
		rules:'推广规则',
		rules1:'邀请你的朋友通过你的邀请链接注册，然后辅助他们开启机器人。',
		rules2:'根据邀请好友开启机器人的订单金额奖励其20%的金额。',
		revenue:'邀请总收益',
		today:'今日邀请人数',
		total:'总邀请人数',
		record:'邀请记录',
		account:'账号',
		money:'金额',
		pay:'付费时间',
	},

	// 404
	notFount:{
		msg:'抱歉，页面出错了！',
		backBtn:'回到首页',
	},
}