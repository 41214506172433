import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'

Vue.use(VueRouter)

// 解决replace定向/问题
const originalPush = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
	return originalPush.call(this, location).catch(err => err)
}

/**
 * requireAuth 登陆访问权限
 * allowAccess 允许登陆时访问(公共页面)
 * title 页面标题-移动端侧边栏展示			如：子钱包管理
 * activeMenuName 选中的侧边栏-子模块标题	如：手动操盘
 * activeMenu	选中的侧边栏-主模块			如：/stocks
 */
const routes = [
	{
		path: '/',
		component: () => import('@/views/home.vue'),
		meta:{
			requireAuth:true,
		},
		redirect: '/light',
		children: [
			{
				path: '/account',
				name: 'Account',
				component: () => import('@/views/account/index.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[0]',
					activeMenuName:'aside.list[0]',
				},
			},
			// {
			// 	path: '/noviceGuide',
			// 	name: 'noviceGuide',
			// 	component: () => import('@/views/stocks/noviceGuide.vue'),
			// 	meta:{
			// 		requireAuth:true,
			// 		title:'aside.list[1]',
			// 		activeMenuName:'aside.list[1]',
			// 	},
			// },
			{
				path: '/stocks',
				name: 'Stocks',
				component: () => import('@/views/stocks/index.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[1]',
					activeMenuName:'aside.list[1]',
				},
			},
			{
				path: '/stocksFull',
				name: 'stocksFull',
				component: () => import('@/views/stocks/stocksFull.vue'),
				meta:{
					requireAuth:true,
					title:'stocks.advanced',
					activeMenu: '/stocks',
				},
			},
			{
				path: '/subManage',
				name: 'SubManage',
				component: () => import('@/views/stocks/subManage.vue'),
				meta:{
					requireAuth:true,
					title:'common.subManage',
					activeMenu: '/stocks',
					activeMenuName:'aside.list[1]',
				},
			},
			{
				path: '/record',
				name: 'Record',
				component: () => import('@/views/stocks/record.vue'),
				meta:{
					requireAuth:true,
					title:'common.transactionRecord',
					activeMenu: '/stocks',
					activeMenuName:'aside.list[1]',
				},
			},
			{
				path: '/positionManagement',
				name: 'positionManagement',
				component: () => import('@/views/stocks/positionManagement.vue'),
				meta:{
					requireAuth:true,
					title:'stocks.positionManagement',
					activeMenu: '/stocks',
					activeMenuName:'aside.list[1]',
				},
			},
			{
				path: '/userRiskManagement',
				name: 'userRiskManagement',
				component: () => import('@/views/stocks/userRiskManagement.vue'),
				meta:{
					requireAuth:true,
					title:'stocks.userRiskManagement',
					activeMenu: '/stocks',
					activeMenuName:'aside.list[1]',
				},
			},
			{
				path: '/userRiskDetail',
				name: 'userRiskDetail',
				component: () => import('@/views/stocks/userRiskDetail.vue'),
				meta:{
					requireAuth:true,
					title:'stocks.userRiskDetail',
					activeMenu: '/stocks',
					activeMenuName:'aside.list[1]',
				},
			},
			{
				path: '/holderList',
				name: 'holderList',
				component: () => import('@/views/stocks/holderList.vue'),
				meta:{
					requireAuth:true,
					title:'tools.holderList.name',
					activeMenu: '/stocks',
					activeMenuName:'aside.list[1]',
				},
			},
			{
				path: '/robot',
				name: 'Robot',
				component: () => import('@/views/robot/index.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[2]',
					activeMenuName:'aside.list[2]',
				},
			},
			{
				path: '/addrobot',
				name: 'robotadd',
				component: () => import('@/views/robot/add.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[2]',
					activeMenu: '/robot',
					activeMenuName:'aside.list[2]',
				},
			},
			{
				path: '/robotlog',
				name: 'robotlog',
				component: () => import('@/views/robot/log.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[3]',
					activeMenuName:'aside.list[3]',
				},
			},
			{
				path: '/data',
				name: 'Data',
				component: () => import('@/views/data/index.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[4]',
					activeMenuName:'aside.list[4]',
				},
			},
			{
				path: '/property',
				name: 'Property',
				component: () => import('@/views/property/index.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[5]',
					activeMenuName:'aside.list[5]',
				},
			},
			{
				path: '/alarm',
				name: 'Alarm',
				component: () => import('@/views/alarm/index.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[6]',
					activeMenuName:'aside.list[6]',
				},
			},
			{
				path: '/renew',
				name: 'Renew',
				component: () => import('@/views/user/renew.vue'),
				meta:{
					requireAuth:true,
					title:'aside.renew',
					activeMenuName:'aside.renew',
				},
			},
			// {
			// 	path: '/recharge',
			// 	name: 'Recharge',
			// 	component: () => import('@/views/user/recharge.vue'),
			// 	meta:{
			// 		requireAuth:true,
			// 		title:'common.recharge',
			// 		activeMenu: '/renew',
			// 		activeMenuName:'aside.renew',
			// 	},
			// },
			// {
			// 	path: '/renewRecord',
			// 	name: 'renewRecord',
			// 	component: () => import('@/views/user/mobile/renewRecord.vue'),
			// 	meta:{
			// 		requireAuth:true,
			// 		title:'renew.renewRecord',
			// 		activeMenuName:'aside.renew',
			// 	},
			// },
			// {
			// 	path: '/withdrawal',
			// 	name: 'Withdrawal',
			// 	component: () => import('@/views/user/withdrawal.vue'),
			// 	meta:{
			// 		requireAuth:true,
			// 		title:'common.withdrawal',
			// 		activeMenu: '/renew',
			// 		activeMenuName:'aside.renew',
			// 	},
			// },
			// {
			// 	path: '/withdrawalRecord',
			// 	name: 'withdrawalRecord',
			// 	component: () => import('@/views/user/mobile/withdrawalRecord.vue'),
			// 	meta:{
			// 		requireAuth:true,
			// 		title:'renew.withdrawalRecord',
			// 		activeMenuName:'aside.renew',
			// 	},
			// },
			{
				path: '/user',
				name: 'User',
				component: () => import('@/views/user/index.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[8]',
					activeMenuName:'aside.list[8]',
				},
			},
			// 用户中心子页面*3-移动端
			{
				path: '/centerUser',
				name: 'centerUser',
				component: () => import('@/views/user/mobile/centerUser.vue'),
				meta:{
					requireAuth:true,
					title:'user.data',
					activeMenuName:'aside.list[8]',
				},
			},
			{
				path: '/centerSafe',
				name: 'centerSafe',
				component: () => import('@/views/user/mobile/centerSafe.vue'),
				meta:{
					requireAuth:true,
					title:'user.security',
					activeMenuName:'aside.list[8]',
				},
			},
			{
				path: '/centerLang',
				name: 'centerLang',
				component: () => import('@/views/user/mobile/centerLang.vue'),
				meta:{
					requireAuth:true,
					title:'user.language',
					activeMenuName:'aside.list[8]',
				},
			},
			{
				path: '/centerTheme',
				name: 'centerTheme',
				component: () => import('@/views/user/mobile/centerTheme.vue'),
				meta:{
					requireAuth:true,
					title:'user.model',
					activeMenuName:'aside.list[8]',
				},
			},
			{
				path: '/invite',
				name: 'Invite',
				component: () => import('@/views/user/invite.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[9]',
					activeMenuName:'aside.list[9]',
				},
			},
			// 邀请记录-移动端
			{
				path: '/inviteRecord',
				name: 'InviteRecord',
				component: () => import('@/views/user/mobile/inviteRecord.vue'),
				meta:{
					requireAuth:true,
					title:'invite.record',
					activeMenuName:'aside.list[9]',
				},
			},
			{
				path: '/course',
				name: 'Course',
				component: () => import('@/views/support/course.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[11]',
					activeMenuName:'aside.list[11]',
				},
			},
			{
				path: '/contact',
				name: 'Contact',
				component: () => import('@/views/support/contact.vue'),
				meta:{
					requireAuth:true,
					title:'aside.list[12]',
					activeMenuName:'aside.list[12]',
				},
			},
		]
	},
	// 官网系列
	{
		path: '/home',
		component: () => import('@/views/officialWebsite/home.vue'),
		redirect: '/light',
		children: [
			{
				path: '/light',
				name: 'light',
				component: () => import('@/views/officialWebsite/index.vue'),
				meta:{
					title:'common.title',
				},
			},
			{
				path: '/agreement',
				name: 'agreement',
				component: () => import('@/views/officialWebsite/agreement.vue'),
				meta:{
					title:'website.agreement.protocol',
					// metaData:[
					// 	...commonMetaData,
					// 	// common
					// 	{name:'title',content:'User Agreements'},
					// 	// og
					// 	{name:'og:title',content:'User Agreements'},
					// 	// twitter
					// 	{name:'twitter:title',content:'User Agreements'},
					// ],
				},
			},
			{
				path: '/problem',
				name: 'problem',
				component: () => import('@/views/officialWebsite/problem.vue'),
				meta:{
					title:'website.index.problem',
					// metaData:[
					// 	...commonMetaData,
					// 	// common
					// 	{name:'title',content:'Common Issues'},
					// 	// og
					// 	{name:'og:title',content:'Common Issues'},
					// 	// twitter
					// 	{name:'twitter:title',content:'Common Issues'},
					// ],
				},
			},
			{
				path: '/homeContact',
				name: 'homeContact',
				component: () => import('@/views/officialWebsite/homeContact.vue'),
				meta:{
					title:'common.contact',
					// metaData:[
					// 	...commonMetaData,
					// 	// common
					// 	{name:'title',content:'Contact us'},
					// 	// og
					// 	{name:'og:title',content:'Contact us'},
					// 	// twitter
					// 	{name:'twitter:title',content:'Contact us'},
					// ],
				},
			},

			{
				path: '/login',
				name: 'login',
				component: () => import('@/views/login/sign.vue'),
				meta:{
					title:'common.login',
				},
			},
			{
				path: '/safeLogin',
				name: 'safeLogin',
				component: () => import('@/views/login/safeLogin.vue'),
				meta:{
					title:'login.safeLogin',
				},
			},
			{
				path: '/forget',
				name: 'forget',
				component: () => import('@/views/forget/forget.vue'),
				meta:{
					title:'login.reset',
				},
			},
			{
				path: '/register',
				name: 'register',
				component: () => import('@/views/register/register.vue'),
				meta:{
					title:'login.register',
					allowAccess:true,
				},
			},
		]
	},
	{
		path: '/twitter',
		name: 'twitter',
		component: () => import('@/views/source/twitter.vue')
	},
	{
		path: '/qrcode',
		name: 'qrcode',
		component: () => import('@/views/source/qrcode.vue')
	},
	{
		path: '*', 
		component: () => import('@/views/notFound.vue'),
		meta:{
			allowAccess:true,
		},
	}
]

const router = new VueRouter({
	// history: createWebHistory(),
	// mode: 'hash',
	
	mode: 'history',
	// base:'/light_vue/',
	routes,
})

// 路由守卫需优化，本地过期时间判定等
router.beforeEach((to, from, next) => {
	// if (window.vm&&to.meta.title) {
	// 	document.title = window.vm.$t(to.meta.title);
	// }
	
	if (to.meta.requireAuth) {
		// 添加账号状态完成
		localStorage.setItem('addAccount',null)
		if (!!getToken()) {
			// 是否有测试权限要求
			// if(to.meta.testMode){
			// 	if(localStorage.getItem("light_account")=='qqqqqq1'){
			// 		next()
			// 	}else{
			// 		next({
			// 			path: '/notFound',
			// 		})
			// 	}
			// }else{
				next()
			// }
		} else {
			// 未登录
			next({
				path: '/login',
				query: { redirect: to.fullPath }
			});
		}
	} else {
		// 如果进入不需要登录权限的页面时
        var login_time=localStorage.getItem('login_time')
		var validity=login_time+24*3600*1000
        var login_time_now=new Date().getTime()

		if(to.meta.allowAccess){//允许登录时访问的部分公共页面
			//如果进注册（邀请链接），则保留登录状态进注册页，若注册成功清除登录状态
			next()
		}else{
			// 有存储的登陆时间且未超过24小时的且有token，则免登陆
			if(!!login_time&&login_time_now<validity&&!!getToken()){
                // 检查是否为切换账号-添加账号操作
                if (localStorage.getItem('addAccount')==1) {
                    // 允许访问登录页以添加账号
                    next();
                } else {
                    // 否则，自动跳转到其他页面
                    next({ path: '/stocks' });
                }
			}else{
				next()
			}
		}
	}
})

export default router
